import { useCallback, useEffect, useState } from 'react';
import fetchFromBackend from '../api/fetchFromBackend';
import { AllFerienDTO } from '../model/FerienDTO';
import { AxiosError } from 'axios';

export default function useFerien() {
  const [ferien, setFerien] = useState<AllFerienDTO | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | AxiosError>(null);
  const path = 'ferien';

  const load = useCallback(() => {
    setLoading(true);
    fetchFromBackend<AllFerienDTO>(path)
      .then(setFerien)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [path]);

  useEffect(() => load(), [load]);

  return { ferien, loading, error, reloadFerien: load };
}
