import styles from './WelcomePage.module.css';

export default function WelcomePage() {
  return (
    <div className={styles.welcomePageContainer}>
      <div className={styles.box}>
        <h1>Herzlich Willkommen</h1>
        <p>
          bei <span className={'yellowText'}>tce</span>vent
        </p>
        <p>dem Reservierungsportal des TanzCentrum Ettlingen</p>
        <p className={styles.yellow}>...da willst du hin!</p>
      </div>
    </div>
  );
}
