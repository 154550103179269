import { CourseDTO } from '../../../../model/CourseDTO';
import { Button, Menu, MenuItem, Paper } from '@mui/material';
import TrafficLight from '../../../../component/TrafficLight';
import styles from './InternCoursePaper.module.css';
import TodayIcon from '@mui/icons-material/Today';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LoginIcon from '@mui/icons-material/Login';
import EditIcon from '@mui/icons-material/Edit';
import { InternCoursePageProps } from '../InternCoursePage';
import { toLocalDateWithDayString } from '../../../../util/toLocalDateString';
import { useState } from 'react';
import useDeleteEvent from '../../../../hooks/useDeleteEvent';
import CustomCircularProgress from '../../../../util/customLoadingSpinner';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorPage, { ERROR_CODE_INTERN_COURSE_PAPER_DELETE } from '../../../../error/ErrorPage';
import { useAuth } from '../../../../context/AuthContext';
import axios from 'axios';
import getTrafficLightStatus from '../../../../util/getTrafficLightStatus';
import { useConfigContext } from '../../../../context/ConfigContext';
import { CategoryType } from '../edit/EditCoursePage';

type CoursePaperProps = {
  course: CourseDTO;
  reload: () => void;
};
export default function InternCoursePaper({
  course,
  setSite,
  setEditId,
  reload,
  setCourse,
  category
}: CoursePaperProps & InternCoursePageProps & { category: CategoryType }) {
  const config = useConfigContext();
  const trafficLightStatus = getTrafficLightStatus(course, category, config);
  const { error, isLoading, deleteData } = useDeleteEvent(course.id);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const { getHeader } = useAuth();
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = async (option: string) => {
    if (option === 'edit') {
      setSite('editCourse');
      setEditId(course.id);
    }
    if (option === 'delete') {
      if (window.confirm('Bist du dir sicher?')) {
        await deleteData(reload);
      }
    }
    handleClose();
  };
  async function handleDownloadReservations() {
    let url = `https://api.ettlingen-tanzt.de/reservationsCSV/${course.id}`;
    const header = getHeader();

    let response = await axios({
      url,
      method: 'GET',
      responseType: 'blob', // important, It's specifying what type of data to be served from the server to the browser
      headers: header
    });

    const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = urlBlob;
    const today = new Date();
    const todayString = today.toLocaleDateString();
    const title = `${course.title}_${course.topic}_${course.startDate}__Stand_${todayString}`
      .replaceAll(' ', '_')
      .replaceAll('.', '_');
    link.setAttribute('download', `${title}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  if (error) return <ErrorPage location={ERROR_CODE_INTERN_COURSE_PAPER_DELETE} error={error} />;

  if (isLoading) {
    return <CustomCircularProgress />;
  }
  return (
    <div>
      <Paper variant={'outlined'} className={styles.paper}>
        <div className={styles.contentContainer}>
          <div className={styles.trafficLightContainer}>
            <TrafficLight status={trafficLightStatus} />
          </div>
          <div className={styles.dataContainer}>
            <div className={styles.noSpacingContainer}>
              <h1>
                {course.title} ({course.noOfParticipants}/{course.minNoOfParticipants})
              </h1>
              <p>{course.topic}</p>
            </div>
            <div className={styles.timeAndDateContainer}>
              <div className={styles.timeContainer}>
                <TodayIcon className={styles.timeIcon} />
                <h2 style={{ color: '#ffffff' }}>{toLocalDateWithDayString(course.startDate)}</h2>
              </div>
              <div className={styles.timeContainer}>
                <ScheduleIcon className={styles.timeIcon} />
                <h2 style={{ color: '#ffffff' }}>{course.startTime}</h2>
              </div>
            </div>
            <div className={styles.buttonSection}>
              <Button
                className={styles.registerButton}
                onClick={() => {
                  setSite('reservations');
                  setEditId(course.id);
                  setCourse(course);
                }}>
                Reservierungen
                <LoginIcon />
              </Button>
              <div>
                <Button className={styles.registerButton} onClick={handleClick}>
                  Aktionen
                  <EditIcon />
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}>
                  <MenuItem onClick={() => handleMenuItemClick('edit')}>Bearbeiten</MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigator.clipboard
                        .writeText(`https://tcevent.tanzcentrum-ettlingen.de/reserve/${course.id}`)
                        .then(() => setAnchorEl(null));
                    }}>
                    Reservierungslink kopieren
                  </MenuItem>
                  <MenuItem onClick={handleDownloadReservations}>
                    Reservierungen herunterladen
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick('delete')}>Löschen</MenuItem>
                </Menu>
              </div>
              <Button className={styles.registerButton} onClick={toggleExpand}>
                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Button>
            </div>
          </div>
        </div>
      </Paper>
      {isExpanded && (
        <div className={`${styles.coursePaperDetails} ${isExpanded ? styles.expanded : ''}`}>
          {course.details !== '' && course.details !== undefined ? (
            <div>
              <h3>Weitere Informationen</h3>
              <p>{course.details}</p>
            </div>
          ) : (
            <h3>Keine weiteren Informationen vorhanden</h3>
          )}
        </div>
      )}
    </div>
  );
}
