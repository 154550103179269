import React, { useState } from 'react';
import styles from './Overview.module.css';
import Signet from '../../assets/signet_tce.png';
import WorkshopsPage from '../coursePage/WorkshopsPage';
import SpecialCoursesPage from '../coursePage/SpecialCoursesPage';
import RefreshsPage from '../coursePage/RefreshsPage';
import SuccessPage from '../success/SuccessPage';
import DatesOverview from '../DatesOverview/DatesOverview';
import WelcomePage from './WelcomePage';
import TCEEventsPage from '../coursePage/TCEEventsPage';
import Clubfinder from '../clubfinder/Clubfinder';
import { useConfigContext } from '../../context/ConfigContext';
import { useNavigate } from 'react-router-dom';
import { ConfigResponse } from '../../model/ConfigResponse';
import {
  CalendarMonth,
  Celebration,
  Close,
  EmojiEvents,
  Festival,
  HomeRepairService,
  Info,
  Lightbulb,
  Menu,
  People,
  Refresh
} from '@mui/icons-material';
import {
  Divider,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import AboutPage from '../about/AboutPage';
import MembershipInfoPage from '../MembershipInfo/MembershipInfoPage';
import YouthpartyPage from '../coursePage/YouthpartyPage';

export type sites =
  | 'workshops'
  | 'refresh'
  | 'specialCourses'
  | 'success'
  | 'dates'
  | 'youthDates'
  | 'tceevents'
  | 'clubfinder'
  | 'membershipInfos'
  | 'about';

type OverviewProps = {
  initalSite?: sites;
};

type MainNavProps = {
  config: ConfigResponse;
  site: sites | undefined;
};

function Overview({ initalSite }: OverviewProps) {
  const [site, setSite] = useState<sites | undefined>(initalSite);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const config = useConfigContext();

  return (
    <div>
      <header className={styles.overviewHeader}>
        <div className={styles.overviewImageContainer} onClick={() => setSite(undefined)}>
          <img alt="logo" src={Signet} />
          <h1>
            <span className={'yellowText'}>tce</span>
            <span className={'grayText'}>
              vent<span className={'whiteText'}>.</span>
            </span>
          </h1>
        </div>

        <nav>
          <ul>
            <li>
              {!drawerOpen ? (
                <Menu onClick={() => setDrawerOpen(!drawerOpen)} />
              ) : (
                <Close onClick={() => setDrawerOpen(!drawerOpen)} />
              )}
            </li>
          </ul>
        </nav>
      </header>
      <main className={styles.overviewMain}>
        <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)} anchor={'right'}>
          <Box sx={{ width: 400, backgroundColor: '#ffcc00', height: '100vh' }} role="presentation">
            <div
              className={styles.drawerHeader}
              style={{
                padding: '2.5em',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'black'
              }}>
              <div
                className={styles.overviewImageContainer}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '1em',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <img alt="logo" src={Signet} height={'50'} />
                <h3>
                  <span className={'yellowText'}>tce</span>
                  <span className={'grayText'}>
                    vent<span className={'whiteText'}>.</span>
                  </span>
                </h3>
              </div>
              <IconButton
                onClick={() => setDrawerOpen(false)}
                style={{ backgroundColor: '#aaaaaaaa', color: 'black' }}>
                <Close />
              </IconButton>
            </div>
            <Divider color={'white'} />
            <DrawerContent
              config={config}
              setSite={(destination) => {
                setSite(destination);
                setDrawerOpen(false);
              }}
              site={site}
            />
          </Box>
        </Drawer>
        <MainNav config={config} site={site} />
      </main>
    </div>
  );
}

function MainNav({ site, config }: MainNavProps): JSX.Element {
  // in your component
  const navigate = useNavigate();

  if (site === undefined) {
    return <WelcomePage />;
  } else if (site === 'dates') {
    if (config.DATES_ENABLED_WEB === 'true') {
      return <DatesOverview />;
    } else {
      navigate('/');
      return <div />;
    }
  } else if (site === 'clubfinder') {
    if (config.CLUBFINDER_ENABLED === 'true') {
      return <Clubfinder />;
    } else {
      navigate('/');
      return <div />;
    }
  } else if (site === 'success') {
    return <SuccessPage />;
  } else if (site === 'workshops') {
    if (config.WORKSHOPS_ENABLED === 'true') {
      return <WorkshopsPage />;
    } else {
      navigate('/');
      return <div />;
    }
  } else if (site === 'tceevents') {
    if (config.EVENTS_ENABLED === 'true') {
      return <TCEEventsPage />;
    } else {
      navigate('/');
      return <div />;
    }
  } else if (site === 'specialCourses') {
    if (config.TCECOURSES_ENABLED === 'true') {
      return <SpecialCoursesPage />;
    } else {
      navigate('/');
      return <div />;
    }
  } else if (site === 'refresh') {
    if (config.REFRESH_ENABLED === 'true') {
      return <RefreshsPage />;
    } else {
      navigate('/');
      return <div />;
    }
  } else if (site === 'youthDates') {
    if (config.YOUTH_DATES_ENABLED === 'true') {
      return <YouthpartyPage />;
    } else {
      navigate('/');
      return <div />;
    }
  } else if (site === 'about') {
    if (config.ABOUT_ENABLED === 'true') {
      return <AboutPage />;
    } else {
      navigate('/');
      return <div />;
    }
  } else if (site === 'membershipInfos') {
    if (config.MEMBERSHIP_INFOS_ENABLED === 'true') {
      return <MembershipInfoPage />;
    } else {
      navigate('/');
      return <div />;
    }
  }
  return <div />;
}

type DrawerProps = {
  config: ConfigResponse;
  setSite: (destination: sites | undefined) => unknown;
  site: sites | undefined;
};

function DrawerContent({ config, setSite, site }: DrawerProps) {
  return (
    <div className={styles.drawerContent}>
      <div>
        <Divider />
        {config.DATES_ENABLED_WEB === 'true' ? (
          <ListItem
            disablePadding
            style={{ backgroundColor: site === 'dates' ? '#c0c0c0aa' : '#fc0' }}>
            <ListItemButton onClick={() => setSite('dates')}>
              <ListItemIcon>{<CalendarMonth />}</ListItemIcon>
              <ListItemText primary={'Tanzabende und Ferien'} />
            </ListItemButton>
          </ListItem>
        ) : null}
        <Divider />
        {config.YOUTH_DATES_ENABLED === 'true' ? (
          <ListItem
            disablePadding
            style={{ backgroundColor: site === 'youthDates' ? '#c0c0c0aa' : '#fc0' }}>
            <ListItemButton onClick={() => setSite('youthDates')}>
              <ListItemIcon>{<Celebration />}</ListItemIcon>
              <ListItemText primary={'Jugend'} />
            </ListItemButton>
          </ListItem>
        ) : null}
        <Divider />
        {config.CLUBFINDER_ENABLED === 'true' ? (
          <ListItem
            disablePadding
            style={{ backgroundColor: site === 'clubfinder' ? '#c0c0c0aa' : '#fc0' }}>
            <ListItemButton onClick={() => setSite('clubfinder')}>
              <ListItemIcon>{<People />}</ListItemIcon>
              <ListItemText primary={'Clubfinder'} />
            </ListItemButton>
          </ListItem>
        ) : null}
        <Divider />
        {config.TCECOURSES_ENABLED === 'true' ? (
          <ListItem
            disablePadding
            style={{ backgroundColor: site === 'specialCourses' ? '#c0c0c0aa' : '#fc0' }}>
            <ListItemButton onClick={() => setSite('specialCourses')}>
              <ListItemIcon>{<SearchIcon />}</ListItemIcon>
              <ListItemText primary={'TCE-Kurse'} />
            </ListItemButton>
          </ListItem>
        ) : null}
        <Divider />
        {config.REFRESH_ENABLED === 'true' ? (
          <ListItem
            disablePadding
            style={{ backgroundColor: site === 'refresh' ? '#c0c0c0aa' : '#fc0' }}>
            <ListItemButton onClick={() => setSite('refresh')}>
              <ListItemIcon>{<Refresh />}</ListItemIcon>
              <ListItemText primary={'Refreshtanzen'} />
            </ListItemButton>
          </ListItem>
        ) : null}
        <Divider />
        {config.EVENTS_ENABLED === 'true' ? (
          <ListItem
            disablePadding
            style={{ backgroundColor: site === 'tceevents' ? '#c0c0c0aa' : '#fc0' }}>
            <ListItemButton onClick={() => setSite('tceevents')}>
              <ListItemIcon>{<EmojiEvents />}</ListItemIcon>
              <ListItemText primary={'Events'} />
            </ListItemButton>
          </ListItem>
        ) : null}
        <Divider />
        {config.WORKSHOPS_ENABLED === 'true' ? (
          <ListItem
            disablePadding
            style={{ backgroundColor: site === 'workshops' ? '#c0c0c0aa' : '#fc0' }}>
            <ListItemButton onClick={() => setSite('workshops')}>
              <ListItemIcon>{<HomeRepairService />}</ListItemIcon>
              <ListItemText primary={'Workshops'} />
            </ListItemButton>
          </ListItem>
        ) : null}
        <Divider />
        {config.MEMBERSHIP_INFOS_ENABLED === 'true' ? (
          <>
            <Divider />
            <ListItem
              disablePadding
              style={{ backgroundColor: site === 'membershipInfos' ? '#c0c0c0aa' : '#fc0' }}>
              <ListItemButton onClick={() => setSite('membershipInfos')}>
                <ListItemIcon>{<Lightbulb />}</ListItemIcon>
                <ListItemText primary={'Informationen zur Mitgliedschaft'} />
              </ListItemButton>
            </ListItem>
          </>
        ) : null}
        <Divider />
        {config.T5_ENABLED === 'true' ? (
          <>
            <Divider />
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => window.open('https://www.t5-event.de', '_blank', 'noreferrer')}>
                <ListItemIcon>{<Festival />}</ListItemIcon>
                <ListItemText primary={'Tanzschule mieten'} />
              </ListItemButton>
            </ListItem>
          </>
        ) : null}
        <Divider />
      </div>
      <div>
        {config.ABOUT_ENABLED === 'true' ? (
          <>
            <Divider />
            <Divider />
            <ListItem
              disablePadding
              style={{ backgroundColor: site === 'about' ? '#c0c0c0aa' : '#fc0' }}>
              <ListItemButton onClick={() => setSite('about')}>
                <ListItemIcon>{<Info />}</ListItemIcon>
                <ListItemText primary={'Über tcevent'} />
              </ListItemButton>
            </ListItem>
            <Divider />
          </>
        ) : null}
      </div>
    </div>
  );
}

export default Overview;
