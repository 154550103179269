import { useCallback, useEffect, useState } from 'react';
import { AllCoursesDTO } from '../model/CourseDTO';
import fetchAuthenticatedFromBackend from '../api/fetchAuthenticatedFromBackend';
import { useAuth } from '../context/AuthContext';

export default function useInternCourses(path: string) {
  const [courses, setCourses] = useState<AllCoursesDTO | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const { getHeader, logout } = useAuth();

  const load = useCallback(() => {
    setLoading(true);
    const header = getHeader();
    fetchAuthenticatedFromBackend<AllCoursesDTO>(path, header)
      .then((r) => {
        setCourses(r);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          logout();
        } else {
          setError(err);
        }
      })
      .finally(() => setLoading(false));
  }, [getHeader, logout, path]);

  useEffect(() => load(), [load]);

  return { courses, loading, error, reload: load };
}
