import { useEffect } from 'react';
import styles from './newsfeed.module.css';
import NewsCarousel from './Carousel/NewsCarousel';
import useNews from '../../hooks/useNews';
import ErrorPage from '../../error/ErrorPage';
import CustomCircularProgress from '../../util/customLoadingSpinner';
import Snowfall from 'react-snowfall';
import { useConfigContext } from '../../context/ConfigContext';
import { useWebSocketReload } from '../../hooks/useWebsocketReload';

export default function Newsfeed() {
  const { news, error, loading, reload } = useNews();
  const { isConnected } = useWebSocketReload(reload);
  const config = useConfigContext();
  useEffect(() => {
    const timer = setTimeout(
      () => {
        window.location.reload();
      },
      12 * 60 * 60 * 1000
    ); // Setzt den Timer auf drei Stunden

    return () => clearTimeout(timer);
  }, []);

  if (error) return <ErrorPage location={41} error={error} />;

  if (loading || news === null) {
    return <CustomCircularProgress />;
  }

  const newsList = [
    ...news,
    {
      id: 'dashboard_courses',
      headline: '',
      subheadline: '',
      text: ''
    },
    {
      id: 'dashboard_events',
      headline: '',
      subheadline: '',
      text: ''
    },
    {
      id: 'dashboard_tanzabende',
      headline: '',
      subheadline: '',
      text: ''
    }
  ];
  console.log(isConnected);
  return (
    <div className={styles.container}>
      {config.NEWSFEED_SNOWFALL === 'true' && <Snowfall />}
      <NewsCarousel
        newsList={newsList}
        displayDuration={10000}
        easterLogo={config.USE_EASTER_LOGO}
      />
    </div>
  );
}
