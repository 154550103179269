import { TanzabendDTO } from '../../../model/TanzabendDTO';
import styles from './TanzabendDetails.module.css';
import { toLocalDateWithDayString } from '../../../util/toLocalDateString';

type TanzabendDetailsProps = {
  tanzabend: TanzabendDTO;
};
export default function TanzabendDetails({ tanzabend }: TanzabendDetailsProps) {
  return (
    <div className={styles.tanzabendDetailContainer}>
      <div className={styles.tanzabendDetailTimeContainer}>
        <p>{toLocalDateWithDayString(tanzabend.date)}</p>
        <p> - </p>
        <p className={styles.detailTime}>{tanzabend.time}</p>
      </div>
      <p>{tanzabend.title}</p>
    </div>
  );
}
