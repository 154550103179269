import React, { useState } from 'react';
import styles from './InternPage.module.css';
import Signet from '../../assets/signet_tce.png';
import SuccessPage from '../../features/success/SuccessPage';
import InternWorkshopsPage from './coursePage/starters/InternWorkshopsPage';
import InternSpecialCoursesPage from './coursePage/starters/InternSpecialCoursesPage';
import InternRefreshsPage from './coursePage/starters/InternRefreshsPage';
import EditCoursePage from './coursePage/edit/EditCoursePage';
import ReservationsPage from './Reservations/ReservationsPage';
import InternWelcomePage from './InternWelcomePage';
import { CourseDTO } from '../../model/CourseDTO';
import { useAuth } from '../../context/AuthContext';
import LoginPage from './Login/LoginPage';
import LogoutIcon from '@mui/icons-material/Logout';
import InternDatesOverview from './DatesOverview/InternDatesOverview';
import EditTanzabendPage from './DatesOverview/Edit/EditTanzabendPage';
import { TanzabendDTO } from '../../model/TanzabendDTO';
import { FerienDTO } from '../../model/FerienDTO';
import EditFerienPage from './DatesOverview/Edit/EditFerienPage';
import InternTCEEventsPage from './coursePage/starters/InternTCEEventsPage';
import { ClubDTO } from '../../model/ClubDTO';
import InternClubfinder from './clubfinder/InternClubfinder';
import EditClubPage from './clubfinder/EditClubPage';
import {
  Divider,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import {
  CalendarMonth,
  Celebration,
  Close,
  EmojiEvents,
  HomeRepairService,
  Info,
  Lightbulb,
  Logout,
  Menu,
  People,
  Refresh,
  Settings
} from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton/IconButton';
import AboutPage from '../about/AboutPage';
import MembershipInfoPage from '../MembershipInfo/MembershipInfoPage';
import InternSonstigesPage from './coursePage/starters/InternSonstigesPage';
import InternYouthpartysPage from './coursePage/starters/InternYouthpartysPage';

export type sites =
  | 'workshops'
  | 'refresh'
  | 'specialCourses'
  | 'success'
  | 'editCourse'
  | 'reservations'
  | 'dates'
  | 'editHoliday'
  | 'editTanzabend'
  | 'tceevents'
  | 'editClub'
  | 'clubfinder'
  | 'youthDates'
  | 'membershipInfos'
  | 'about'
  | 'sonstiges'
  | 'editYouthparty';

type OverviewProps = {
  initalSite?: sites;
};

function InternPage({ initalSite }: OverviewProps) {
  const [prevSite, setPrevSite] = useState<sites | undefined>(initalSite);
  const [site, setSite] = useState<sites | undefined>(initalSite);
  const [editId, setEditId] = useState<string>('');
  const [reservationsForCourse, setReservationsForCourse] = useState<CourseDTO | null>(null);
  const [tanzabend, setTanzabend] = useState<TanzabendDTO | null>(null);
  const [ferien, setFerien] = useState<FerienDTO | null>(null);
  const [club, setClub] = useState<ClubDTO | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { isLoggedIn, logout } = useAuth();

  const setSiteFunction = (targetSite: sites) => {
    setPrevSite(site);
    setSite(targetSite);
  };
  return (
    <div>
      <header className={styles.internPageHeader}>
        <div className={styles.internPageImageContainer} onClick={() => setSite(undefined)}>
          <img alt="logo" src={Signet} />
          <h1>
            <span className={'yellowText'}>tce</span>
            <span className={'grayText'}>
              vent<span className={'whiteText'}>.</span>
            </span>
          </h1>
        </div>
        <nav className={styles.internPageNavigationBar}>
          {isLoggedIn ? (
            <ul>
              <li>
                <button className="link-button" onClick={logout}>
                  <LogoutIcon />
                </button>
              </li>
              <li>
                {!drawerOpen ? (
                  <Menu onClick={() => setDrawerOpen(!drawerOpen)} />
                ) : (
                  <Close onClick={() => setDrawerOpen(!drawerOpen)} />
                )}
              </li>
            </ul>
          ) : null}
        </nav>
      </header>
      <main>
        {!isLoggedIn ? (
          <LoginPage />
        ) : (
          <>
            <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)} anchor={'right'}>
              <Box
                sx={{ width: 400, backgroundColor: '#ffcc00', height: '100vh' }}
                role="presentation">
                <div
                  className={styles.drawerHeader}
                  style={{
                    padding: '2.5em',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: 'black'
                  }}>
                  <div
                    className={styles.overviewImageContainer}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '1em',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                    <img alt="logo" src={Signet} height={'50'} />
                    <h3>
                      <span className={'yellowText'}>tce</span>
                      <span className={'grayText'}>
                        vent<span className={'whiteText'}>.</span>
                      </span>
                    </h3>
                  </div>
                  <IconButton
                    onClick={() => setDrawerOpen(false)}
                    style={{ backgroundColor: '#aaaaaaaa', color: 'black' }}>
                    <Close />
                  </IconButton>
                </div>
                <Divider color={'white'} />
                <DrawerContent
                  setSite={(destination) => {
                    setSite(destination);
                    setDrawerOpen(false);
                  }}
                  site={site}
                />
              </Box>
            </Drawer>
            <div>
              {site === undefined ? <InternWelcomePage /> : null}
              {site === 'success' ? <SuccessPage /> : null}
              {site === 'about' ? <AboutPage /> : null}
              {site === 'membershipInfos' ? <MembershipInfoPage /> : null}
              {site === 'dates' ? (
                <InternDatesOverview
                  setTanzabend={setTanzabend}
                  setFerien={setFerien}
                  setSite={setSiteFunction}
                />
              ) : null}
              {site === 'youthDates' ? (
                <InternYouthpartysPage
                  setCourse={setReservationsForCourse}
                  setSite={setSiteFunction}
                  setEditId={setEditId}
                />
              ) : null}
              {site === 'editTanzabend' ? (
                <EditTanzabendPage
                  tanzabend={tanzabend}
                  close={() => {
                    setSite(prevSite);
                    setTanzabend(null);
                    setPrevSite(undefined);
                  }}
                />
              ) : null}
              {site === 'editHoliday' ? (
                <EditFerienPage
                  ferien={ferien}
                  close={() => {
                    setSite(prevSite);
                    setFerien(null);
                    setPrevSite(undefined);
                  }}
                />
              ) : null}
              {site === 'workshops' ? (
                <InternWorkshopsPage
                  setCourse={setReservationsForCourse}
                  setSite={setSiteFunction}
                  setEditId={setEditId}
                />
              ) : null}
              {site === 'sonstiges' ? (
                <InternSonstigesPage
                  setCourse={setReservationsForCourse}
                  setSite={setSiteFunction}
                  setEditId={setEditId}
                />
              ) : null}
              {site === 'tceevents' ? (
                <InternTCEEventsPage
                  setCourse={setReservationsForCourse}
                  setSite={setSiteFunction}
                  setEditId={setEditId}
                />
              ) : null}
              {site === 'specialCourses' ? (
                <InternSpecialCoursesPage
                  setCourse={setReservationsForCourse}
                  setSite={setSiteFunction}
                  setEditId={setEditId}
                />
              ) : null}
              {site === 'refresh' ? (
                <InternRefreshsPage
                  setCourse={setReservationsForCourse}
                  setSite={setSiteFunction}
                  setEditId={setEditId}
                />
              ) : null}
              {site === 'reservations' ? (
                <ReservationsPage
                  id={editId}
                  course={reservationsForCourse}
                  close={() => {
                    setEditId('');
                    setSite(prevSite);
                    setPrevSite(undefined);
                  }}
                />
              ) : null}
              {site === 'editCourse' && editId ? (
                <EditCoursePage
                  id={editId}
                  close={() => {
                    setEditId('');
                    setSite(prevSite);
                    setPrevSite(undefined);
                  }}
                />
              ) : null}
              {site === 'clubfinder' ? (
                <InternClubfinder setClub={setClub} setSite={setSiteFunction} />
              ) : null}
              {site === 'editClub' ? (
                <EditClubPage
                  club={club}
                  close={() => {
                    setClub(null);
                    setSite(prevSite);
                    setPrevSite(undefined);
                  }}
                />
              ) : null}
            </div>
          </>
        )}
      </main>
    </div>
  );
}

type DrawerProps = {
  setSite: (destination: sites | undefined) => unknown;
  site: sites | undefined;
};
function DrawerContent({ setSite, site }: DrawerProps) {
  const { logout } = useAuth();
  return (
    <div className={styles.drawerContent}>
      <div>
        <Divider />
        <ListItem
          disablePadding
          style={{ backgroundColor: site === 'dates' ? '#c0c0c0aa' : '#fc0' }}>
          <ListItemButton onClick={() => setSite('dates')}>
            <ListItemIcon>{<CalendarMonth />}</ListItemIcon>
            <ListItemText primary={'Tanzabende und Ferien'} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem
          disablePadding
          style={{ backgroundColor: site === 'youthDates' ? '#c0c0c0aa' : '#fc0' }}>
          <ListItemButton onClick={() => setSite('youthDates')}>
            <ListItemIcon>{<Celebration />}</ListItemIcon>
            <ListItemText primary={'Jugendpartys'} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem
          disablePadding
          style={{ backgroundColor: site === 'clubfinder' ? '#c0c0c0aa' : '#fc0' }}>
          <ListItemButton onClick={() => setSite('clubfinder')}>
            <ListItemIcon>{<People />}</ListItemIcon>
            <ListItemText primary={'Clubfinder'} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem
          disablePadding
          style={{ backgroundColor: site === 'specialCourses' ? '#c0c0c0aa' : '#fc0' }}>
          <ListItemButton onClick={() => setSite('specialCourses')}>
            <ListItemIcon>{<SearchIcon />}</ListItemIcon>
            <ListItemText primary={'TCE-Kurse'} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem
          disablePadding
          style={{ backgroundColor: site === 'refresh' ? '#c0c0c0aa' : '#fc0' }}>
          <ListItemButton onClick={() => setSite('refresh')}>
            <ListItemIcon>{<Refresh />}</ListItemIcon>
            <ListItemText primary={'Refreshtanzen'} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem
          disablePadding
          style={{ backgroundColor: site === 'tceevents' ? '#c0c0c0aa' : '#fc0' }}>
          <ListItemButton onClick={() => setSite('tceevents')}>
            <ListItemIcon>{<EmojiEvents />}</ListItemIcon>
            <ListItemText primary={'Events'} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem
          disablePadding
          style={{ backgroundColor: site === 'workshops' ? '#c0c0c0aa' : '#fc0' }}>
          <ListItemButton onClick={() => setSite('workshops')}>
            <ListItemIcon>{<HomeRepairService />}</ListItemIcon>
            <ListItemText primary={'Workshops'} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem
          disablePadding
          style={{ backgroundColor: site === 'sonstiges' ? '#c0c0c0aa' : '#fc0' }}>
          <ListItemButton onClick={() => setSite('sonstiges')}>
            <ListItemIcon>{<Settings />}</ListItemIcon>
            <ListItemText primary={'Sonstiges'} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem
          disablePadding
          style={{ backgroundColor: site === 'membershipInfos' ? '#c0c0c0aa' : '#fc0' }}>
          <ListItemButton onClick={() => setSite('membershipInfos')}>
            <ListItemIcon>{<Lightbulb />}</ListItemIcon>
            <ListItemText primary={'Informationen zur Mitgliedschaft'} />
          </ListItemButton>
        </ListItem>
        <Divider />
      </div>
      <div>
        <Divider />
        <Divider />
        <ListItem
          disablePadding
          style={{ backgroundColor: site === 'about' ? '#c0c0c0aa' : '#fc0' }}>
          <ListItemButton onClick={() => setSite('about')}>
            <ListItemIcon>{<Info />}</ListItemIcon>
            <ListItemText primary={'Über tcevent'} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton onClick={logout}>
            <ListItemIcon>{<Logout />}</ListItemIcon>
            <ListItemText primary={'Logout'} />
          </ListItemButton>
        </ListItem>
        <Divider />
      </div>
    </div>
  );
}

export default InternPage;
