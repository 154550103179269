import { useCallback, useEffect, useState } from 'react';
import { AllCoursesDTO } from '../model/CourseDTO';
import fetchFromBackend from '../api/fetchFromBackend';
export default function useSpecialCourses() {
  const [specials, setSpecials] = useState<AllCoursesDTO | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const path = 'specials';

  const load = useCallback(() => {
    setLoading(true);
    fetchFromBackend<AllCoursesDTO>(path)
      .then(setSpecials)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [path]);
  useEffect(() => load(), [load]);

  return { specials, loading, error, reload: load };
}
