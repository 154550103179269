const colorMap: { [key: number]: string } = {
  1: '#ffffcc', // Sehr helles Gelb
  2: '#ffff99', // Helles Gelb
  3: '#ffcc99', // Blasses Orange
  4: '#ffcc00', // Dunkleres Orange
  5: '#ff9933', // Orange-Braun
  6: '#ff6600', // Dunkles Orange
  7: '#993300', // Dunkles Braun
  8: '#993366', // Braun-Lila
  9: '#663333', // Dunkleres Braun
  10: '#333333' // Sehr dunkles Grau
};

export default function getDifficultyColor(number: number): string {
  return colorMap[number] || 'Invalid Number'; // Standardwert für ungültige Zahlen
}
