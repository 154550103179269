// AuthContext.tsx
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import useLogin from '../hooks/useLogin';
import { HeaderType } from '../model/HeaderType';

type AuthContextType = {
  isLoggedIn: boolean;
  login: (username: string, password: string, onFailure: () => void) => void;
  logout: () => void;
  getHeader: () => HeaderType;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [sessionToken, setSessionToken] = useState<undefined | string>(() => {
    const storedState = localStorage.getItem('sessionToken');
    return storedState !== null ? storedState : undefined;
  });

  const [username, setUsername] = useState<string | undefined>(() => {
    const storedState = localStorage.getItem('username');
    return storedState !== null ? storedState : undefined;
  });

  const { tryLogin, checkSessionTokenValid } = useLogin();

  useEffect(() => {
    if (sessionToken !== undefined) {
      checkSessionTokenValid(sessionToken)
        .then(() => {
          localStorage.setItem('sessionToken', sessionToken);
          setIsLoggedIn(true);
        })
        .catch(() => {
          console.log('failure');
          localStorage.removeItem('sessionToken');
          setSessionToken(undefined);
          setIsLoggedIn(false);
        });
    }
  }, [checkSessionTokenValid, sessionToken]);

  useEffect(() => {
    if (username !== undefined) {
      localStorage.setItem('username', username);
    }
  }, [username]);

  const login = (username: string, password: string, onFailure: () => void) =>
    tryLogin(
      username,
      password,
      (token: string) => {
        setSessionToken(token);
        setUsername(username);
        setIsLoggedIn(true);
      },
      onFailure
    );

  const logout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('sessionToken');
    setSessionToken(undefined);
    setUsername(undefined);
    setIsLoggedIn(false);
  };

  const getHeader = (): HeaderType => {
    return { username: username!, sessiontoken: sessionToken! };
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, getHeader }}>
      {children}
    </AuthContext.Provider>
  );
};
