import Box from '@mui/material/Box';
import React from 'react';
import styles from './AFContainer.module.css';
import { Divider } from '@mui/material';
import EmailButton from './EmailButton';

export default function AFContainer() {
  return (
    <Box className={styles.container}>
      <div className={styles.content}>
        <h1>Kurssystem</h1>

        <ul className={styles.ul}>
          <li>
            Laufzeit: <span className={styles.important}>10 Termine</span>
          </li>
          <li>
            Preis: ab <span className={styles.important}>€ 149</span>
          </li>
          <li>Nur für Einsteiger-, Aufsteiger- und Bronzekurse möglich</li>
          <li> Abbuchung in einem Betrag vor Kursbeginn</li>
          <li> Preis pro Person</li>
          <li>
            Kein Unterricht in den{' '}
            <a
              href={'https://tcevent.tanzcentrum-ettlingen.de/dates'}
              target={'_blank'}
              rel="noreferrer">
              Tanzschulferien
            </a>
          </li>
          <li> Kündigung 4 Unterrichtseinheiten vor Kursende</li>
          <Divider style={{ backgroundColor: '#fff', width: '100%' }} />
          <li>1 Tanztermin / Woche / 60 Minuten</li>
          <li>Wechsel in andere Clubs nach Absprache möglich</li>
        </ul>
      </div>
      <EmailButton membershipType={'Kurssystem'} />
    </Box>
  );
}
