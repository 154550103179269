import { useCallback, useEffect, useState } from 'react';
import { ReservationsDTO } from '../model/ReservationsDTO';
import { AxiosError } from 'axios';
import deleteRegistration from '../api/deleteRegistration';
import fetchAuthenticatedFromBackend from '../api/fetchAuthenticatedFromBackend';
import { useAuth } from '../context/AuthContext';

export default function useReservations(id: string) {
  const [reservations, setReservations] = useState<ReservationsDTO[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | AxiosError>(null);
  const [deleteError, setDeleteError] = useState<null | AxiosError>(null);
  const { getHeader, logout } = useAuth();

  let load: () => void;
  load = useCallback(() => {
    setLoading(true);
    setError(null);
    const header = getHeader();
    fetchAuthenticatedFromBackend<ReservationsDTO[]>(`allUserReservationsForEvent/${id}`, header)
      .then((res) => {
        setReservations(res);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          logout();
        } else {
          setError(err);
        }
      })
      .finally(() => setLoading(false));
  }, [getHeader, id, logout]);
  useEffect(() => {
    load();
  }, [load]);

  const deleteReservation = useCallback(
    async (deleteId: string) => {
      const header = getHeader();
      setLoading(true);
      setDeleteError(null);

      try {
        await deleteRegistration(deleteId, header);
        load();
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          logout();
        } else {
          setDeleteError(error as AxiosError);
        }
      } finally {
        setLoading(false);
      }
    },
    [getHeader, load, logout]
  );

  return { reservations, loading, fetchError: error, deleteError, reload: load, deleteReservation };
}
