import CustomCircularProgress from '../../util/customLoadingSpinner';
import CoursePaper from './CoursePaper';
import React, { useEffect, useState } from 'react';
import { AllCoursesDTO, CourseDTO } from '../../model/CourseDTO';
import styles from './CoursePage.module.css';
import SearchBar from '../../component/SearchBar';
import removeDuplicate from '../../util/removeDuplicate';
import InfoSection from './InfoSection';
import { CategoryType } from '../intern/coursePage/edit/EditCoursePage';

type CoursePageProps = {
  courses: AllCoursesDTO | null;
  loading: boolean;
  error: string | null;
  pageTitle: string;
  category: CategoryType;
};

export default function CoursePage({ courses, loading, pageTitle, category }: CoursePageProps) {
  const [displayedItems, setDisplayedItems] = useState<CourseDTO[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (courses) {
      setDisplayedItems([]);
      removeDuplicate<CourseDTO>(courses.allCourses, (a, b) => a.id === b.id).forEach(
        (item, index) => {
          setTimeout(() => {
            setDisplayedItems((currentItems) => [...currentItems, item]);
          }, 10 * index); // 100ms Verzögerung für jedes Element
        }
      );
    }
  }, [courses]);

  const matchesSearchTerm = (course: CourseDTO) => {
    return (
      course.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      course.topic.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  if (loading || courses === null) {
    return <CustomCircularProgress />;
  }

  return (
    <div>
      <div className={styles.searchContainer}>
        <h1>
          <span className={'grayText'}>{pageTitle}</span>
          <span className={'whiteText'}>.</span>{' '}
        </h1>
      </div>
      <SearchBar onSearchTermChange={setSearchTerm} />
      <InfoSection pageTitle={pageTitle} />
      <div className={styles.paperContainer}>
        {removeDuplicate<CourseDTO>(displayedItems, (a, b) => a.id === b.id)
          .filter((it) => matchesSearchTerm(it))
          .map((item) => (
            <div key={item.id} className={'fadeIn'}>
              <CoursePaper course={item} category={category} />
            </div>
          ))}
        {displayedItems.filter((it) => matchesSearchTerm(it)).length === 0 ? (
          <p className={styles.emptyState}>
            Aktuell gibt es leider keine Einträge, die zu den gewählten Kriterien passen
          </p>
        ) : null}
      </div>
    </div>
  );
}
