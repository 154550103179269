import axios from 'axios';
import { HeaderType } from '../model/HeaderType';

export default async function fetchAuthenticatedFromBackend<T>(
  path: string,
  header: HeaderType
): Promise<T> {
  const backendUrl = 'https://api.ettlingen-tanzt.de';
  const response = await axios.get(`${backendUrl}/${path}`, { headers: header });
  return await response.data;
}
