import { AxiosError } from 'axios';
import styles from './ErrorPage.module.css';

export const ERROR_CODE_REGISTRATION_FORM_GET_COURSE = 15 as const;
export const ERROR_CODE_REGISTRATION_FORM_SEND = 14 as const;
export const ERROR_CODE_INTERN_COURSE_PAPER_DELETE = 13 as const;
export const ERROR_CODE_EDIT_COURSE_PAGE_FETCH = 12 as const;
export const ERROR_CODE_EDIT_COURSE_PAGE_SEND = 11 as const;
export const ERROR_CODE_RESERVATION_PAGE_FETCH = 10 as const;
export const ERROR_CODE_RESERVATION_PAGE_DELETE = 9 as const;
export const ERROR_CODE_INTERN_DATES_OVERVIEW_TANZABENDE = 8 as const;
export const ERROR_CODE_INTERN_DATES_OVERVIEW_FERIEN = 7 as const;
export const ERROR_CODE_EDIT_TANZABEND_SEND = 25 as const;
export const ERROR_CODE_EDIT_TANZABEND_DELETE = 24 as const;
export const ERROR_CODE_EDIT_FERIEN_SEND = 23 as const;
export const ERROR_CODE_EDIT_FERIEN_DELETE = 22 as const;
export const ERROR_CODE_DATES_OVERVIEW_TANZABENDE = 21 as const;
export const ERROR_CODE_DATES_OVERVIEW_FERIEN = 20 as const;
export const ERROR_CODE_INTERN_CLUB_FINDER_GET = 19 as const;
export const ERROR_CODE_CLUB_FINDER_GET = 18 as const;
export const ERROR_CODE_EDIT_CLUB_SEND = 17 as const;
export const ERROR_CODE_EDIT_CLUB_DELETE = 16 as const;
export const ERROR_CODE_LOAD_REMOTE_CONFIG = 1 as const;

export default function ErrorPage({ location, error }: { location: number; error: AxiosError }) {
  console.log(Date.now(), 'ERROR: ', location, ': ', error.toJSON());
  return (
    <div className={styles.container}>
      <p>Hoppla, da hat etwas nicht funktioniert.</p>
      <p> Bitte probiere es nochmal.</p>
      <p className={styles.yellow}>
        ID: {location} - {error.status ?? '99'}
      </p>
    </div>
  );
}
