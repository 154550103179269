import React, { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import useSendDelete from '../../../hooks/useSendDelete';
import { RequestType } from '../../../api/sendToBackend';
import ErrorPage, {
  ERROR_CODE_EDIT_CLUB_DELETE,
  ERROR_CODE_EDIT_CLUB_SEND
} from '../../../error/ErrorPage';
import CustomCircularProgress from '../../../util/customLoadingSpinner';
import formTheme from '../../registration/FormTheme';
import removeEmojis from '../../../util/removeEmojis';
import { ClubDTO } from '../../../model/ClubDTO';
import usePostInternData from '../../../hooks/usePostInternData';

export default function EditClubPage({ club, close }: { club: ClubDTO | null; close: () => void }) {
  const [day, setDay] = useState(club?.day ?? '');
  const [time, setTime] = useState(club?.time ?? '');
  const [teacher, setTeacher] = useState(club?.teacher ?? '');
  const [level, setLevel] = useState(club?.level ?? 5);

  const path: string = !club ? 'clubs' : `clubs/${club.id}`;
  const method: RequestType = !club ? 'post' : 'patch';
  const { isLoading, error, postData } = usePostInternData(path, method);
  const { deleteLoading, deleteError, deleteData } = useSendDelete('clubs', club?.id ?? '0');

  const isFormValid = () => {
    return !(!day || !time || !teacher || !level) && !(level < 0) && !(level > 10);
  };

  const handleDelete = () => {
    const handleSuccess = () => {
      close();
    };
    deleteData(handleSuccess);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    postData(
      {
        day: removeEmojis(day),
        time: removeEmojis(time),
        teacher: removeEmojis(teacher),
        level: level
      },
      close
    );
  };
  if (error) return <ErrorPage location={ERROR_CODE_EDIT_CLUB_SEND} error={error} />;
  if (deleteError) return <ErrorPage location={ERROR_CODE_EDIT_CLUB_DELETE} error={deleteError} />;
  if (isLoading || deleteLoading) {
    return <CustomCircularProgress />;
  }
  return (
    <ThemeProvider theme={formTheme}>
      <div style={{ paddingBottom: '8vh' }}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            mt: 1,
            backgroundColor: 'background.default',
            color: 'text.primary',
            borderRadius: '1.5em',
            marginInline: '5em',
            marginTop: '5em',
            marginBottom: '-5em',
            padding: '2em'
          }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              variant={'standard'}
              label="Wochentag"
              autoFocus
              value={day}
              onChange={(e) => setDay(e.target.value)}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              variant={'standard'}
              label="Uhrzeit"
              type="time"
              InputLabelProps={{ shrink: true }}
              value={time}
              onChange={(e) => setTime(e.target.value)}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              variant={'standard'}
              label="Kursleiter"
              InputLabelProps={{ shrink: true }}
              value={teacher}
              onChange={(e) => setTeacher(e.target.value)}
            />

            <TextField
              margin="normal"
              required={false}
              fullWidth
              variant={'standard'}
              label="Level"
              type="number"
              inputProps={{ min: 0, max: 10 }}
              value={`${level}`}
              onChange={(e) => {
                if (e.target.value === '') {
                  setLevel(0);
                } else {
                  setLevel(Number.parseInt(e.target.value));
                }
              }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
            <Button
              type={'button'}
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              onClick={close}>
              Abbrechen
            </Button>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!isFormValid()}
              sx={{ mt: 3, mb: 2 }}>
              Absenden
            </Button>
          </div>

          {club && (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="warning"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleDelete}>
                Löschen
              </Button>
            </div>
          )}
        </Box>
      </div>
    </ThemeProvider>
  );
}
