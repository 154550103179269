import { useCallback, useState } from 'react';
import { RequestType } from '../api/sendToBackend';
import { AxiosError, AxiosResponse } from 'axios';
import sendAuthenticatedToBackend from '../api/sendAuthenticatedToBackend';
import { useAuth } from '../context/AuthContext';

const usePostData = (path: string, method: RequestType) => {
  const [response, setResponse] = useState<AxiosResponse | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { getHeader, logout } = useAuth();

  const postData = useCallback(
    (data: any, onSuccess?: () => void) => {
      const header = getHeader();
      setIsLoading(true);
      sendAuthenticatedToBackend<typeof data>(path, method, data, header)
        .then((r) => {
          setResponse(r);
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            logout();
          } else {
            setError(err);
          }
        })
        .finally(() => setIsLoading(false));
    },
    [getHeader, logout, method, path]
  );

  return { response, error, isLoading, postData };
};

export default usePostData;
