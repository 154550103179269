import InternCoursePage, { InternCoursePageProps } from '../InternCoursePage';
import useSonstiges from '../../../../hooks/useSonstiges';

export default function InternSonstigesPage({
  setSite,
  setEditId,
  setCourse
}: InternCoursePageProps) {
  const { sonstiges, loading, error, reload } = useSonstiges();
  return (
    <InternCoursePage
      courses={sonstiges}
      loading={loading}
      error={error}
      pageTitle={'Sonstiges'}
      category={'sonstiges'}
      setSite={setSite}
      setEditId={setEditId}
      reload={reload}
      setCourse={setCourse}
    />
  );
}
