import CoursePage from './CoursePage';
import useTCEEvents from '../../hooks/useTCEEvents';

export default function TCEEventsPage() {
  const { tceevents, loading, error } = useTCEEvents();
  return (
    <CoursePage
      courses={tceevents}
      loading={loading}
      error={error}
      pageTitle={'TCE-Events'}
      category={'tceevent'}
    />
  );
}
