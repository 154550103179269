import styles from './InternWelcomePage.module.css';

export default function InternWelcomePage() {
  return (
    <div className={styles.welcomePageContainer}>
      <div className={styles.box}>
        <h1>Herzlich Willkommen</h1>
        <p>
          auf dem Administrationsportal von tce<span className={styles.yellow}>vent</span>{' '}
        </p>
        <p className={styles.yellow}>...da willst du hin!</p>
      </div>
    </div>
  );
}
