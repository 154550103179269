import useWorkshops from '../../hooks/useWorkshops';
import CoursePage from './CoursePage';

export default function WorkshopsPage() {
  const { workshops, loading, error } = useWorkshops();
  return (
    <CoursePage
      courses={workshops}
      loading={loading}
      error={error}
      pageTitle={'Workshops'}
      category={'workshop'}
    />
  );
}
