import { useCallback, useEffect, useState } from 'react';
import fetchFromBackend from '../api/fetchFromBackend';
import { AxiosError } from 'axios';
import { NewsDTO } from '../model/NewsDTO';

export default function useNews() {
  const [news, setNews] = useState<NewsDTO[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | AxiosError>(null);

  const load = useCallback(() => {
    setLoading(true);
    fetchFromBackend<NewsDTO[]>('news')
      .then(setNews)
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => load(), [load]);

  return { news, loading, error, reload: load };
}
