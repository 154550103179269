import { useCallback, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import sendDelete from '../api/sendDelete';
import { useAuth } from '../context/AuthContext';

const useSendDelete = (path: string, id: string) => {
  const [response, setResponse] = useState<AxiosResponse | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { getHeader, logout } = useAuth();

  const deleteData = useCallback(
    (onSuccess: () => void) => {
      setIsLoading(true);
      const hedaer = getHeader();
      sendDelete(path, id, hedaer)
        .then((r) => {
          setResponse(r);
          onSuccess();
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            logout();
          } else {
            setError(err);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [getHeader, id, logout, path]
  );

  return { deleteResponse: response, deleteError: error, deleteLoading: isLoading, deleteData };
};

export default useSendDelete;
