import { useEffect, useState } from 'react';
import { AllCoursesDTO, CourseDTO } from '../../../model/CourseDTO';
import removeDuplicate from '../../../util/removeDuplicate';
import CoursePaper from '../../coursePage/CoursePaper';

type CourseDashboardProps = {
  title: string;
  courses: AllCoursesDTO;
};
export default function CoursesDashboard({ title, courses }: CourseDashboardProps) {
  const [displayedItems, setDisplayedItems] = useState<CourseDTO[]>([]);

  useEffect(() => {
    if (courses) {
      setDisplayedItems([]);
      removeDuplicate<CourseDTO>(courses.allCourses, (a, b) => a.id === b.id).forEach(
        (item, index) => {
          setTimeout(() => {
            setDisplayedItems((currentItems) => [...currentItems, item]);
          }, 300 * index); // 100ms Verzögerung für jedes Element
        }
      );
    }
  }, [courses]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
      }}>
      <div style={{ paddingLeft: '10em', paddingRight: '10em', paddingTop: '6em' }}>
        <h1 style={{ color: '#fff' }}>
          Die nächsten <span style={{ color: '#fc0' }}>{title}</span>:
        </h1>
        {removeDuplicate<CourseDTO>(displayedItems, (a, b) => a.id === b.id)
          .slice(0, 6)
          .map((item) => {
            return (
              <div className={'fadeIn'} style={{ padding: '0.5em' }}>
                <CoursePaper course={item} category={'newsfeed'} />
              </div>
            );
          })}
      </div>
    </div>
  );
}
