import axios, { AxiosResponse } from 'axios';

export type RequestType = 'post' | 'patch';
export default async function sendToBackend<T>(
  path: string,
  method: RequestType,
  data: T
): Promise<AxiosResponse> {
  const backendUrl = `https://api.ettlingen-tanzt.de/${path}`;
  function makeRequest<T>(url: string, data: any) {
    if (method === 'post') {
      return axios.post<T>(url, data);
    } else {
      return axios.patch<T>(url, data);
    }
  }
  return await makeRequest(backendUrl, data);
}
