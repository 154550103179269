import React, { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import formTheme from '../../../../features/registration/FormTheme';
import CustomCircularProgress from '../../../../util/customLoadingSpinner';
import { RequestType } from '../../../../api/sendToBackend';
import useSendDelete from '../../../../hooks/useSendDelete';
import { FerienDTO } from '../../../../model/FerienDTO';
import ErrorPage, {
  ERROR_CODE_EDIT_FERIEN_DELETE,
  ERROR_CODE_EDIT_FERIEN_SEND
} from '../../../../error/ErrorPage';
import removeEmojis from '../../../../util/removeEmojis';
import usePostInternData from '../../../../hooks/usePostInternData';

export default function EditFerienPage({
  ferien,
  close
}: {
  ferien: FerienDTO | null;
  close: () => void;
}) {
  const [title, setTitle] = useState(ferien?.title ?? '');
  const [startDate, setStartDate] = useState(ferien?.startDate ?? '');
  const [endDate, setEndDate] = useState(ferien?.endDate ?? '');

  const path: string = !ferien ? 'ferien' : `ferien/${ferien.id}`;
  const method: RequestType = !ferien ? 'post' : 'patch';
  const { isLoading, error, postData } = usePostInternData(path, method);
  const { deleteLoading, deleteError, deleteData } = useSendDelete('ferien', ferien?.id ?? '0');

  const isFormValid = () => {
    return !(!title || !endDate || !startDate);
  };

  const handleDelete = () => {
    const handleSuccess = () => {
      close();
    };
    deleteData(handleSuccess);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    postData(
      {
        title: removeEmojis(title),
        startDate: removeEmojis(startDate),
        endDate: removeEmojis(endDate)
      },
      close
    );
  };
  if (error) return <ErrorPage location={ERROR_CODE_EDIT_FERIEN_SEND} error={error} />;
  if (deleteError)
    return <ErrorPage location={ERROR_CODE_EDIT_FERIEN_DELETE} error={deleteError} />;
  if (isLoading || deleteLoading) {
    return <CustomCircularProgress />;
  }
  return (
    <ThemeProvider theme={formTheme}>
      <div style={{ paddingBottom: '8vh' }}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            mt: 1,
            backgroundColor: 'background.default',
            color: 'text.primary',
            borderRadius: '1.5em',
            marginInline: '5em',
            marginTop: '5em',
            marginBottom: '-5em',
            padding: '2em'
          }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              variant={'standard'}
              label="Titel"
              autoFocus
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              variant={'standard'}
              label="Startdatum"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              variant={'standard'}
              label="Enddatum"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
            <Button
              type={'button'}
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              onClick={close}>
              Abbrechen
            </Button>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!isFormValid()}
              sx={{ mt: 3, mb: 2 }}>
              Absenden
            </Button>
          </div>

          {ferien && (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="warning"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleDelete}>
                Löschen
              </Button>
            </div>
          )}
        </Box>
      </div>
    </ThemeProvider>
  );
}
