import InternCoursePage, { InternCoursePageProps } from '../InternCoursePage';
import useInternCourses from '../../../../hooks/useInternCourses';

export default function InternSpecialCoursesPage({
  setSite,
  setEditId,
  setCourse
}: InternCoursePageProps) {
  const { courses, loading, error, reload } = useInternCourses('intern/specials');
  return (
    <InternCoursePage
      courses={courses}
      loading={loading}
      error={error}
      pageTitle={'TCE-Kurse'}
      category={'special'}
      setEditId={setEditId}
      setSite={setSite}
      reload={reload}
      setCourse={setCourse}
    />
  );
}
