import { useCallback, useEffect, useState } from 'react';
import fetchFromBackend from '../api/fetchFromBackend';
import { AllTanzabendeDTO } from '../model/TanzabendDTO';
import { AxiosError } from 'axios';

export default function useTanzabende(intern: boolean = false) {
  const [tanzabende, setTanzabende] = useState<AllTanzabendeDTO | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | AxiosError>(null);

  const path = intern ? 'intern/tanzabende' : 'tanzabende';

  const load = useCallback(() => {
    setLoading(true);
    fetchFromBackend<AllTanzabendeDTO>(path)
      .then(setTanzabende)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [path]);

  useEffect(() => load(), [load]);

  return { tanzabende, loadTanzabende: loading, tanzabendeError: error, reloadTanzabende: load };
}
