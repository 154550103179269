import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { CSSTransition } from 'react-transition-group';
import styles from './SearchBar.module.css';

type SearchBarProps = {
  onSearchTermChange: (searchTerm: string) => void;
};

export default function SearchBar({ onSearchTermChange }: SearchBarProps) {
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const handleToggleSearch = () => {
    onSearchTermChange('');
    setSearchOpen(!searchOpen);
    if (!searchOpen) {
      setSearchValue('');
    }
  };

  return (
    <div className={styles.searchBarContainer}>
      <CSSTransition
        in={searchOpen}
        timeout={300}
        classNames={{
          enter: styles['slide-enter'],
          enterActive: styles['slide-enter-active'],
          exit: styles['slide-exit'],
          exitActive: styles['slide-exit-active']
        }}
        unmountOnExit
      >
        <div className={styles.centerAlign}>
          <TextField
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            placeholder="Suchen..."
            variant="outlined"
            size="small"
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                onSearchTermChange(searchValue);
                ev.preventDefault();
              }
            }}
            className={styles.textField}
          />
          <IconButton
            className={styles.iconButtons}
            onClick={() => onSearchTermChange(searchValue)}
          >
            <SearchIcon />
          </IconButton>
        </div>
      </CSSTransition>
      <IconButton className={styles.iconButtons} onClick={handleToggleSearch}>
        {searchOpen ? <CloseIcon /> : <SearchIcon />}
      </IconButton>
    </div>
  );
}
