import { CourseDTO } from '../model/CourseDTO';
import { ConfigResponse } from '../model/ConfigResponse';
import { CategoryType } from '../features/intern/coursePage/edit/EditCoursePage';
import { toLocalDateWithDayString } from './toLocalDateString';
import { getDeadlineForCategory } from './getDeadlineForCategory';

export default function calculateDeadline(
  course: CourseDTO,
  config: ConfigResponse,
  category: CategoryType
) {
  const startDate = new Date(course.startDate);
  const deadlineDays = getDeadlineForCategory(category, config);
  const deadlineDate = new Date(startDate);
  deadlineDate.setDate(deadlineDate.getDate() - deadlineDays);
  if (course.noOfParticipants >= course.minNoOfParticipants && !course.reservable) {
    return 'Das Event ist ausgebucht!';
  }
  if (course.minNoOfParticipants === 0) {
    return 'Keine Reservierung notwendig!';
  }
  if (course.noOfParticipants >= course.minNoOfParticipants) {
    return 'Reservierungen sind weiterhin möglich!';
  }
  if (new Date() > deadlineDate) {
    return `Reservierungsschluss war am ${toLocalDateWithDayString(deadlineDate.toLocaleDateString('de'))} um ${course.startTime} Uhr `;
  }
  return `Reservierungsschluss ist am ${toLocalDateWithDayString(deadlineDate.toLocaleDateString('de'))} um ${course.startTime} Uhr `;
}
