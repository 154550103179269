import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import formTheme from '../../../registration/FormTheme';
import CustomCircularProgress from '../../../../util/customLoadingSpinner';
import useCourseById from '../../../../hooks/useCourseById';
import removeEmojis from '../../../../util/removeEmojis';
import ErrorPage, {
  ERROR_CODE_EDIT_COURSE_PAGE_FETCH,
  ERROR_CODE_EDIT_COURSE_PAGE_SEND
} from '../../../../error/ErrorPage';
import { RequestType } from '../../../../api/sendToBackend';
import usePostInternData from '../../../../hooks/usePostInternData';

export type CategoryType =
  | 'refresh'
  | 'workshop'
  | 'special'
  | 'tceevent'
  | 'newsfeed'
  | 'youthparty'
  | 'sonstiges';
export type DisplayCategoryType = 'Refresh' | 'Workshop' | 'TCEKurs' | 'Event' | 'Jugendparty';
export default function EditCoursePage({ id, close }: { id: string; close: () => void }) {
  const { course, loading, fetchError } = useCourseById(id);
  const [title, setTitle] = useState('');
  const [topic, setTopic] = useState('');
  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [category, setCategory] = useState('');
  const [minNoOfPart, setMinNoOfPart] = useState<number>(5);
  const [details, setDetails] = useState('');
  const [reservable, setReservable] = useState(true);
  const [inPairs, setInPairs] = useState(true);
  const categories: CategoryType[] = ['refresh', 'workshop', 'special', 'tceevent', 'youthparty'];
  useEffect(() => {
    if (!loading && course && id !== '0') {
      setTitle(course.title);
      setTopic(course.topic);
      setStartDate(course.startDate);
      setStartTime(course.startTime);
      setCategory(course.category);
      setMinNoOfPart(course.minNoOfParticipants);
      setDetails(course.details);
      setReservable(course.reservable);
      setInPairs(course.inPairs);
    }
  }, [loading, course, id]);
  const path: string = id === '0' ? 'event' : `event/${id}`;
  const method: RequestType = id === '0' ? 'post' : 'patch';
  const { isLoading, error, postData } = usePostInternData(path, method);
  const isFormValid = () => {
    return !(!title || !topic || !startTime || !startDate || !category);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = {
      id: removeEmojis(id),
      title: removeEmojis(title),
      topic: removeEmojis(topic),
      startDate: removeEmojis(startDate),
      startTime: removeEmojis(startTime),
      minNoOfParticipants: minNoOfPart,
      category: removeEmojis(category),
      details: removeEmojis(details),
      reservable: reservable,
      inPairs: inPairs
    };
    postData(data, close);
  };

  const getDisplayCategory = (cat: CategoryType): DisplayCategoryType => {
    if (cat === 'refresh') return 'Refresh';
    if (cat === 'special') return 'TCEKurs';
    if (cat === 'youthparty') return 'Jugendparty';
    if (cat === 'workshop') return 'Workshop';
    return 'Event';
  };
  if (fetchError) {
    return <ErrorPage location={ERROR_CODE_EDIT_COURSE_PAGE_FETCH} error={fetchError} />;
  }
  if (error) {
    return <ErrorPage location={ERROR_CODE_EDIT_COURSE_PAGE_SEND} error={error} />;
  }
  if (isLoading || loading) {
    return <CustomCircularProgress />;
  }
  return (
    <ThemeProvider theme={formTheme}>
      <div style={{ paddingBottom: '10em' }}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            mt: 1,
            backgroundColor: 'background.default',
            color: 'text.primary',
            borderRadius: '1.5em',
            marginInline: '5em',
            marginTop: '5em',
            padding: '2em'
          }}>
          <div>
            <TextField
              margin="normal"
              required
              fullWidth
              variant={'standard'}
              label="Titel"
              autoFocus
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              variant={'standard'}
              label="Thema"
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
            />
          </div>
          <div>
            <TextField
              margin="normal"
              required
              fullWidth
              variant={'standard'}
              label="Startdatum"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              variant={'standard'}
              label="Startzeit"
              type="time"
              InputLabelProps={{ shrink: true }}
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
          </div>
          <div>
            <FormControl fullWidth margin="normal">
              <InputLabel id="category-label">Kategorie</InputLabel>
              <Select
                labelId="category-label"
                id="category-select"
                value={category}
                label="Kategorie"
                onChange={(e) => setCategory(e.target.value as CategoryType)}>
                {categories.map((cat) => (
                  <MenuItem sx={{ color: 'black' }} key={cat} value={cat}>
                    {getDisplayCategory(cat)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin="normal"
              required={false}
              fullWidth
              variant={'standard'}
              label="Mindestteilnehmeranzahl"
              type="number"
              inputProps={{ min: 0 }}
              value={`${minNoOfPart}`}
              onChange={(e) => {
                if (e.target.value === '') {
                  setMinNoOfPart(0);
                } else {
                  setMinNoOfPart(Number.parseInt(e.target.value));
                }
              }}
            />
          </div>
          <div>
            <TextField
              margin="normal"
              required={false}
              fullWidth
              variant={'standard'}
              label="Details"
              type="text"
              multiline
              value={details}
              onChange={(e) => setDetails(e.target.value)}
            />
          </div>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Ausgebucht"
            checked={!reservable}
            onChange={() => setReservable(!reservable)}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Anmeldung NICHT Paarweise"
            checked={!inPairs}
            onChange={() => setInPairs(!inPairs)}
          />
          <div>
            <Button
              type={'button'}
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              onClick={close}>
              Abbrechen
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!isFormValid()}
              sx={{ mt: 3, mb: 2 }}>
              Absenden
            </Button>
          </div>
        </Box>
      </div>
    </ThemeProvider>
  );
}
