import styles from './SuccessPage.module.css';
import JSConfetti from 'js-confetti';
import { useEffect } from 'react';

export default function SuccessPage() {
  useEffect(() => {
    const jsConfetti = new JSConfetti();
    jsConfetti
      .addConfetti({
        confettiColors: ['#ffcc00', '#000000', '#000000'],
        confettiNumber: 500
      })
      .finally(() => {
        jsConfetti.destroyCanvas();
      });
  }, []);

  return (
    <div className={styles.welcomePageContainer}>
      <div className={styles.box}>
        <h1>Vielen Dank!</h1>
        <p>Das hat geklappt!</p>
        <p>
          Bei weitern Rückfragen oder Abmeldungen bitte per Email an{' '}
          <a href={'mailTo:kontakt@ettlingen-tanzt.de'}>Kontakt@ettlingen-tanzt.de</a> wenden!
        </p>
        <a href={'/'} className={'white'}>
          Hier geht es zurück!{' '}
        </a>
      </div>
    </div>
  );
}
