import React from 'react';
import styles from './RegistrationSite.module.css';
import Signet from '../../assets/signet_tce.png';
import { useNavigate, useParams } from 'react-router-dom';
import RegistrationForm from './RegistrationForm';
import useCourseById from '../../hooks/useCourseById';
import CustomCircularProgress from '../../util/customLoadingSpinner';
import ErrorPage, { ERROR_CODE_REGISTRATION_FORM_GET_COURSE } from '../../error/ErrorPage';

export default function RegistrationSite() {
  const { id } = useParams();
  const navigate = useNavigate();
  if (id === undefined) navigate('/');
  const { course, loading, fetchError } = useCourseById(id!);
  if (fetchError)
    return <ErrorPage location={ERROR_CODE_REGISTRATION_FORM_GET_COURSE} error={fetchError} />;
  if (loading || course === null) return <CustomCircularProgress />;
  return (
    <div>
      <header className={styles.header}>
        <div className={styles.brandname}>
          <img alt="logo" src={Signet} />
          <h1>
            <span className={'yellowText'}>tce</span>
            <span className={'grayText'}>
              vent<span className={'whiteText'}>.</span>
            </span>
          </h1>
        </div>

        <nav className={styles.overviewNavigationBar}>
          <ul>
            <li>
              <a href={'/'}>Zurück</a>
            </li>
          </ul>
        </nav>
      </header>
      <main className={styles.registrationFormContainer}>
        {!course.reservable ? (
          <div className={styles.notPossible}>
            <h1>Eine Reservierung ist leider nicht möglich!</h1>
          </div>
        ) : (
          <RegistrationForm id={id ?? ''} event={course} />
        )}
      </main>
    </div>
  );
}
