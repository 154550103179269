import React, { useEffect, useState } from 'react';
import useClubs from '../../../hooks/useClubs';
import { ClubDTO } from '../../../model/ClubDTO';
import removeDuplicate from '../../../util/removeDuplicate';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { sites } from '../InternPage';
import InternClubPaper from './InternClubPaper';
import styles from './InternCLubFinder.module.css';
import CustomCircularProgress from '../../../util/customLoadingSpinner';
import ErrorPage, { ERROR_CODE_INTERN_CLUB_FINDER_GET } from '../../../error/ErrorPage';

type InterClubfinderProps = {
  setSite: (site: sites) => unknown;
  setClub: (club: ClubDTO | null) => unknown;
};

export default function InternClubfinder({ setClub, setSite }: InterClubfinderProps) {
  const { clubs, error, loading } = useClubs();
  const [displayedClubs, setDisplayedClubs] = useState<ClubDTO[]>([]);
  useEffect(() => {
    if (clubs) {
      setDisplayedClubs([]);
      removeDuplicate<ClubDTO>(clubs.allClubs, (a, b) => a.id === b.id).forEach((item, index) => {
        setTimeout(() => {
          setDisplayedClubs((currentItems) => [...currentItems, item]);
        }, 50 * index);
      });
    }
  }, [clubs]);
  if (loading) return <CustomCircularProgress />;
  if (error) return <ErrorPage location={ERROR_CODE_INTERN_CLUB_FINDER_GET} error={error} />;

  return (
    <div style={{ paddingBottom: '10vh' }}>
      <div className={styles.searchContainer}>
        <div className={styles.titleAndPlusContainer}>
          <h1>
            <span className={'grayText'}>Club-Finder</span>
            <span className={'whiteText'}>.</span>
          </h1>
          <IconButton
            className={styles.iconButtons}
            onClick={() => {
              setSite('editClub');
              setClub(null);
            }}>
            <AddIcon />
          </IconButton>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        {removeDuplicate<ClubDTO>(displayedClubs, (a, b) => a.id === b.id).map((club) => {
          return (
            <div className={'fadeIn'}>
              <InternClubPaper club={club} setClub={setClub} setSite={setSite} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
