import { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';
import sendLoginDataToBackend from '../api/sendLoginDataToBackend';
import sendSessionDataToBackend from '../api/sendSessionDataToBackend';

const useLogin = () => {
  const [response, setResponse] = useState<AxiosResponse | null>(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const tryLogin = async (
    username: string,
    password: string,
    onSuccess: (str: string) => void,
    onFailure: () => void
  ) => {
    setIsLoading(true);
    sendLoginDataToBackend({ username: username, password: password })
      .then((r: AxiosResponse) => {
        setResponse(r);
        if (r.data !== false) {
          onSuccess(r.data);
        } else {
          onFailure();
        }
      })
      .catch(setError)
      .finally(() => setIsLoading(false));
  };
  const checkSessionTokenValid = useCallback(async (sessionToken: string) => {
    setIsLoading(true);
    return new Promise<void>(async (resolve, reject) => {
      await sendSessionDataToBackend({ username: 'intern', sessionToken: sessionToken })
        .then((r: AxiosResponse) => {
          if (r.status === 200) {
            resolve();
          } else {
            reject();
          }
        })
        .catch((error) => {
          if (error.status === 404 || error.status === 419) {
            reject();
          } else {
            setError(error);
          }
        })
        .finally(() => setIsLoading(false));
    });
  }, []); //Empty array because there's no dependency for this callback

  return { response, error, isLoading, tryLogin, checkSessionTokenValid };
};

export default useLogin;
