import { useCallback, useEffect, useState } from 'react';
import { AllTanzabendeDTO } from '../model/TanzabendDTO';
import { AxiosError } from 'axios';
import fetchAuthenticatedFromBackend from '../api/fetchAuthenticatedFromBackend';
import { useAuth } from '../context/AuthContext';

export default function useInternTanzabende() {
  const [tanzabende, setTanzabende] = useState<AllTanzabendeDTO | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | AxiosError>(null);
  const { getHeader, logout } = useAuth();

  const path = 'intern/tanzabende';

  const load = useCallback(() => {
    setLoading(true);
    const header = getHeader();
    fetchAuthenticatedFromBackend<AllTanzabendeDTO>(path, header)
      .then(setTanzabende)
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          logout();
        } else {
          setError(err);
        }
      })
      .finally(() => setLoading(false));
  }, [getHeader, logout]);

  useEffect(() => load(), [load]);

  return { tanzabende, loadTanzabende: loading, tanzabendeError: error, reloadTanzabende: load };
}
