import React, { useState } from 'react';
import styles from './EmailButton.module.css';
import usePostData from '../../hooks/usePostData';
import CustomCircularProgress from '../../util/customLoadingSpinner';

type EmailButtonProps = {
  membershipType: string;
};

export default function EmailButton({ membershipType }: EmailButtonProps) {
  const [email, setEmail] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [success, setSuccess] = useState(false);
  const { postData, error, isLoading } = usePostData('membershipInfo', 'post');

  const handleSubmit = (event: any) => {
    event.preventDefault();
    postData({ type: membershipType, email: email }, () => setSuccess(true));
  };

  return (
    <div>
      {!showForm && (
        <button className={styles.button} onClick={() => setShowForm(true)}>
          Mehr Informationen anfordern
        </button>
      )}
      {showForm && (
        <>
          {success ? (
            <div className={styles.success}>
              <p>
                Vielen Dank, <br />
                unser Büro wird sich mit Ihnen in Verbindung setzen
              </p>
              <p>
                Für weitere Anliegen nutzen Sie bitte unseren <br />
                <a href={'mailto:kontakt@ettlingen-tanzt.de'}>Bürokontakt</a>
              </p>
            </div>
          ) : (
            <>
              {isLoading ? (
                <CustomCircularProgress />
              ) : (
                <>
                  {' '}
                  {error ? (
                    <div className={styles.success}>
                      <p>Da hat etwas nicht geklappt, bitte versuchen Sie es erneut!</p>
                      <button className={styles.button} onClick={handleSubmit}>
                        Erneut versuchen
                      </button>
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit} className={styles.form}>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Emailadresse eingeben"
                        className={styles.input}
                        required
                      />
                      <button type="submit" className={styles.button}>
                        Absenden
                      </button>
                    </form>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
