import { useCallback, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import deleteEvent from '../api/deleteEvent';
import { useAuth } from '../context/AuthContext';

const useDeleteEvent = (id: string) => {
  const [response, setResponse] = useState<AxiosResponse | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { getHeader, logout } = useAuth();
  const deleteData = useCallback(
    (onSuccess: () => void) => {
      const header = getHeader();
      setIsLoading(true);
      deleteEvent(id, header)
        .then(setResponse)
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            logout();
          } else {
            setError(err);
          }
        })
        .finally(() => {
          setIsLoading(false);
          onSuccess();
        });
    },
    [getHeader, id, logout]
  );

  return { response, error, isLoading, deleteData };
};

export default useDeleteEvent;
