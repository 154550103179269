import React, { useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import styles from './RegistrationForm.module.css';
import { ThemeProvider } from '@mui/material/styles';
import formTheme from './FormTheme';
import usePostData from '../../hooks/usePostData';
import CustomCircularProgress from '../../util/customLoadingSpinner';
import { useNavigate } from 'react-router-dom';
import ErrorPage, { ERROR_CODE_REGISTRATION_FORM_SEND } from '../../error/ErrorPage';
import removeEmojis from '../../util/removeEmojis';
import { SingleCourseDTO } from '../../model/CourseDTO';
import { toLocalDateWithDayString } from '../../util/toLocalDateString';

export default function RegistrationForm({ id, event }: { id: string; event: SingleCourseDTO }) {
  const [firstNameHim, setFirstNameHim] = useState('');
  const [lastNameHim, setLastNameHim] = useState('');
  const [firstNameHer, setFirstNameHer] = useState('');
  const [lastNameHer, setLastNameHer] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isExistingCustomer, setIsExistingCustomer] = useState(false);
  const [isPremiumMember, setIsPremiumMember] = useState(false);
  const [street, setStreet] = useState('');
  const [houseNumber, setHouseNumber] = useState('');
  const [plz, setPlz] = useState('');
  const [city, setCity] = useState('');
  const [remarks, setRemarks] = useState('');
  const navigate = useNavigate();

  const { isLoading, error, postData } = usePostData('reservation', 'post');
  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const isFormValid = () => {
    if (
      !firstNameHim ||
      (event.inPairs && !firstNameHer) ||
      !lastNameHim ||
      (event.inPairs && !lastNameHer) ||
      !email ||
      !validateEmail(email)
    ) {
      return false;
    }
    if (!isExistingCustomer) {
      if (!street || !houseNumber || !plz || !city) {
        return false;
      }
    }
    return true;
  };

  const getMembershipType = () => {
    if (!isExistingCustomer) return 'Kein Mitglied';
    if (!isPremiumMember) return 'Mitglied Stufe 1';
    return 'Premium Mitglied';
  };

  const handleSubmit = (e: any) => {
    if (!event.inPairs) {
      setFirstNameHer('---');
      setLastNameHer('---');
    }

    e.preventDefault();
    postData({
      eventId: removeEmojis(id),
      customer_name_him: removeEmojis(firstNameHim),
      customer_surname_him: removeEmojis(lastNameHim),
      customer_name_her: removeEmojis(firstNameHer),
      customer_surname_her: removeEmojis(lastNameHer),
      customer_email: removeEmojis(email),
      customer_mobile: removeEmojis(phoneNumber),
      customer_street: removeEmojis(street),
      customer_housenumber: removeEmojis(houseNumber),
      customer_postal: removeEmojis(plz),
      customer_local: removeEmojis(city),
      remarks: removeEmojis(remarks),
      membership_type: getMembershipType(),
      source: 'web'
    }).then(() => navigate('/success'));
  };

  if (error) {
    return <ErrorPage location={ERROR_CODE_REGISTRATION_FORM_SEND} error={error} />;
  }
  if (isLoading) {
    return <CustomCircularProgress />;
  }

  return (
    <ThemeProvider theme={formTheme}>
      <div>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            mt: 1,
            backgroundColor: 'background.default',
            color: 'text.primary',
            borderRadius: '1.5em',
            marginInline: '2em',
            marginTop: '5em',
            marginBottom: '-5em',
            padding: '2em'
          }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '2em', alignItems: 'center' }}>
            <div className={styles.heading}>
              <h2>
                {event.title} | {toLocalDateWithDayString(event.startDate)} | {event.startTime}{' '}
              </h2>
              <h4 className={styles.subHeading}>{event.topic}</h4>
              <p>{event.details}</p>
            </div>
          </div>
          {}
          {event.minNoOfParticipants === 0 ? (
            <h4>Keine Reservierung notwendig!</h4>
          ) : (
            <>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
                <TextField
                  className={styles.formInput}
                  margin="normal"
                  required
                  fullWidth
                  variant={'standard'}
                  label="Vorname 1"
                  autoFocus
                  value={firstNameHim}
                  onChange={(e) => setFirstNameHim(e.target.value)}
                />

                <TextField
                  className={styles.formInput}
                  margin="normal"
                  required
                  fullWidth
                  variant={'standard'}
                  label="Nachname 1"
                  value={lastNameHim}
                  onChange={(e) => setLastNameHim(e.target.value)}
                />
              </div>
              {event.inPairs && (
                <div style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
                  <TextField
                    className={styles.formInput}
                    margin="normal"
                    required
                    fullWidth
                    variant={'standard'}
                    label="Vorname 2"
                    autoFocus
                    value={firstNameHer}
                    onChange={(e) => setFirstNameHer(e.target.value)}
                  />

                  <TextField
                    className={styles.formInput}
                    margin="normal"
                    required
                    fullWidth
                    variant={'standard'}
                    label="Nachname 2"
                    value={lastNameHer}
                    onChange={(e) => setLastNameHer(e.target.value)}
                  />
                </div>
              )}

              <TextField
                className={styles.formInput}
                margin="normal"
                required
                fullWidth
                variant={'standard'}
                label="Email Adresse"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <TextField
                className={styles.formInput}
                margin="normal"
                required={false}
                fullWidth
                variant={'standard'}
                label="Telefonnummer (mobil)"
                type="mobil"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />

              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="TCE-Mitglied"
                checked={isExistingCustomer}
                onChange={() => setIsExistingCustomer(!isExistingCustomer)}
              />
              {isExistingCustomer && (
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Premium Mitglied (Modell 2 und höher)"
                  checked={isPremiumMember}
                  onChange={() => setIsPremiumMember(!isPremiumMember)}
                />
              )}

              {!isExistingCustomer && (
                <div>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      variant={'standard'}
                      label="Straße"
                      value={street}
                      onChange={(e) => setStreet(e.target.value)}
                      style={{ flex: 4 }}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      variant={'standard'}
                      label="Hausnummer"
                      value={houseNumber}
                      onChange={(e) => setHouseNumber(e.target.value)}
                      style={{ flex: 1 }}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      variant={'standard'}
                      label="Postleitzahl"
                      value={plz}
                      onChange={(e) => setPlz(e.target.value)}
                      style={{ flex: 2 }}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      variant={'standard'}
                      label="Ort"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      style={{ flex: 3 }}
                    />
                  </div>
                </div>
              )}
              <div style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
                <TextField
                  margin="normal"
                  fullWidth
                  variant={'standard'}
                  label="Anmerkungen"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  style={{ flex: 3 }}
                />
              </div>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!isFormValid()}
                sx={{ mt: 3, mb: 2 }}>
                Absenden
              </Button>
            </>
          )}
        </Box>
      </div>
    </ThemeProvider>
  );
}
