import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import signet from '../assets/signet_tce.png';
import styles from './customLoadingSpinner.module.css';
import { Backdrop } from '@mui/material';

export default function CustomCircularProgress() {
  const width = 100;
  const aspectRatio = 700 / 559;
  const height = width / aspectRatio;
  return (
    <Backdrop open={true}>
      <Box position="relative" display="inline-flex">
        <CircularProgress size={150} style={{ color: '#ffcc00' }} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img
            src={signet}
            className={styles.pulseAnimation}
            alt={''}
            style={{ width: width, height: height }}
          />
        </Box>
      </Box>
    </Backdrop>
  );
}
