import styles from '../DatesOverview/Details/TanzabendDetails.module.css';
import getDifficultyColor from '../../util/getDifficultyColor';
import { ClubDTO } from '../../model/ClubDTO';

type ClubPaperProps = {
  club: ClubDTO;
};

export default function ClubPaper({ club }: ClubPaperProps) {
  return (
    <div
      className={styles.tanzabendDetailContainer}
      style={{ border: `6px solid ${getDifficultyColor(club.level)}` }}>
      <div className={styles.tanzabendDetailTimeContainer}>
        <p style={{ color: '#fff' }}>{club.day}</p>
        <p style={{ color: '#fff' }}>-</p>
        <p style={{ color: '#fff' }}>{club.time}</p>
        <p />
        <p style={{ color: '#ffcc00' }}>Kursleiter: {club.teacher}</p>
      </div>
    </div>
  );
}
