import React, { useEffect, useState } from 'react';
import './newscarousel.css';
import logo from '../../../assets/signet_tce.png';
import easter from '../../../assets/easteregg.png';
import { NewsDTO } from '../../../model/NewsDTO';
import CoursesDashboard from '../Dashboard/CoursesDashboard';
import useSpecialCourses from '../../../hooks/useSpecialCourses';
import useTCEEvents from '../../../hooks/useTCEEvents';
import useTanzabende from '../../../hooks/useTanzabende';
import TanzabendDashboard from '../Dashboard/TanzabendDashboard';

type NewsCarouselProps = {
  newsList: NewsDTO[];
  displayDuration: number; // Dauer in Millisekunden
  easterLogo: string;
};

export default function NewsCarousel({ newsList, displayDuration, easterLogo }: NewsCarouselProps) {
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString().split(':'));

  const { specials, reload } = useSpecialCourses();
  const { tceevents, reload: reloadEvents } = useTCEEvents();
  const { tanzabende, reloadTanzabende } = useTanzabende();
  useEffect(() => {
    if (currentNewsIndex === 1) {
      reloadEvents();
      reload();
      reloadTanzabende();
    }
  }, [currentNewsIndex, reload, reloadEvents, reloadTanzabende]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString().split(':'));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  // Ändern des News-Items
  useEffect(() => {
    const newsChangeTimer = setInterval(() => {
      setFade(false); // Startet den Fade-Out-Effekt
    }, displayDuration);

    return () => clearInterval(newsChangeTimer);
  }, [currentNewsIndex, displayDuration]);

  // Steuerung des Fade-Effekts
  useEffect(() => {
    let fadeTimer: NodeJS.Timeout;
    if (!fade) {
      fadeTimer = setTimeout(() => {
        setCurrentNewsIndex((prevIndex) => (prevIndex + 1) % newsList.length);
        setFade(true);
      }, 1000);
    }

    return () => clearTimeout(fadeTimer);
  }, [fade, newsList.length]);

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: '2em',
          right: '3em',
          display: 'flex',
          alignItems: 'center',
          padding: '10px',
          background: 'rgba(0,0,0,0.5)' // adjust as needed
        }}>
        {easterLogo === 'true' ? (
          <img src={easter} alt="Logo" style={{ marginRight: '1.5em', height: '12vh' }} />
        ) : (
          <img src={logo} alt="Logo" style={{ marginRight: '1.5em', height: '12vh' }} />
        )}
        <div style={{ color: 'white', fontSize: 40, fontWeight: 'bold' }}>
          {currentTime[0]}:{currentTime[1]}:<span style={{ color: '#fc0' }}>{currentTime[2]}</span>
        </div>
      </div>
      {newsList[currentNewsIndex].id === 'dashboard_courses' ? (
        <div className={`carousel-dashboard ${fade ? 'fade-in' : 'fade-out'}`}>
          {specials && <CoursesDashboard courses={specials} title={'TCE-Sonderkurse'} />}
        </div>
      ) : (
        <>
          {newsList[currentNewsIndex].id === 'dashboard_events' ? (
            <div className={`carousel-dashboard ${fade ? 'fade-in' : 'fade-out'}`}>
              {tceevents && <CoursesDashboard courses={tceevents} title={'Events'} />}
            </div>
          ) : (
            <>
              {newsList[currentNewsIndex].id === 'dashboard_tanzabende' ? (
                <div className={`carousel-dashboard ${fade ? 'fade-in' : 'fade-out'}`}>
                  {tanzabende && <TanzabendDashboard tanzabende={tanzabende} />}
                </div>
              ) : (
                <div className={`news-carousel ${fade ? 'fade-in' : 'fade-out'}`}>
                  <h1
                    style={{
                      color: 'white',
                      fontSize: '300%'
                    }}>
                    {newsList[currentNewsIndex].headline}
                  </h1>
                  <h3
                    style={{
                      color: '#ffcc00',
                      fontSize: '175%'
                    }}>
                    {newsList[currentNewsIndex].subheadline}
                  </h3>
                  <img
                    style={{ height: '50vh' }}
                    src={`https://api.ettlingen-tanzt.de/image/${newsList[currentNewsIndex].id}`}
                    alt={'Bild'}
                  />
                  <div>
                    <p
                      style={{
                        color: 'white',
                        fontSize: '150%',
                        fontWeight: 'bold',
                        paddingTop: '1em',
                        paddingInline: '7em',
                        textAlign: 'center'
                      }}>
                      {newsList[currentNewsIndex].text.split('\n').map((item, key) => {
                        return (
                          <span key={key}>
                            {item}
                            <br />
                          </span>
                        );
                      })}
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
