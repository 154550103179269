import styles from './TanzabendDetails.module.css';
import toLocalDateString from '../../../util/toLocalDateString';
import { FerienDTO } from '../../../model/FerienDTO';

type FerienDetailProps = {
  ferien: FerienDTO;
};
export default function FerienDetails({ ferien }: FerienDetailProps) {
  return (
    <div className={styles.tanzabendDetailContainer}>
      <div className={styles.tanzabendDetailTimeContainer}>
        <p style={{ color: '#ffcc00' }}>{ferien.title}:</p>
        <p>{toLocalDateString(ferien.startDate)}</p>
        <p> - </p>
        <p>{toLocalDateString(ferien.endDate)}</p>
      </div>
    </div>
  );
}
