import useNews from '../../../hooks/useNews';
import ErrorPage from '../../../error/ErrorPage';
import CustomCircularProgress from '../../../util/customLoadingSpinner';
import { useAuth } from '../../../context/AuthContext';
import { Paper } from '@mui/material';
import styles from './Newsfeedoverview.module.css';
import IconButton from '@mui/material/IconButton/IconButton';
import { DeleteOutline } from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import LoginPage from '../../intern/Login/LoginPage';
import useDeleteNews from '../../../hooks/useDeleteNews';

export default function NewsfeedOverview() {
  const { news, loading, error, reload } = useNews();
  const { loading: deleteLoading, error: deleteError, deleteNews } = useDeleteNews();
  const { isLoggedIn, logout } = useAuth();
  const navigate = useNavigate();

  const handleClick = (id: string) => {
    deleteNews(id, reload);
  };

  if (error) return <ErrorPage location={42} error={error} />;
  if (deleteError) return <ErrorPage location={42} error={deleteError} />;

  if (loading || deleteLoading || news === null) {
    return <CustomCircularProgress />;
  }

  if (!isLoggedIn) {
    return <LoginPage />;
  }

  return (
    <div className={styles.newsfeedoverviewContainer} style={{ color: 'white' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '1em',
          padding: '1em'
        }}>
        <h1>Newsfeed Overview</h1>
        <IconButton onClick={() => navigate('/newnews')} style={{ backgroundColor: '#fc0' }}>
          <AddIcon style={{ color: 'white' }} />
        </IconButton>
        <IconButton onClick={logout} style={{ backgroundColor: '#fc0' }}>
          <LogoutIcon style={{ color: 'white' }} />
        </IconButton>
      </div>
      {news.map((item) => {
        return (
          <div style={{ paddingInline: '2em', paddingBlock: '0.5em' }}>
            <Paper style={{ backgroundColor: '#aaaaaaaa', borderRadius: '1em' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <img
                  style={{ width: '4em', objectFit: 'cover' }}
                  src={`https://api.ettlingen-tanzt.de/image/${item.id}`}
                  alt={'Bild'}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingBlock: '0.25em',
                    paddingInline: '1em',
                    width: '100%'
                  }}>
                  <div>
                    <h1 style={{ color: 'white' }}>{item.headline}</h1>
                    <h2 style={{ color: '#ffcc00' }}>{item.subheadline}</h2>
                  </div>
                  <div style={{ flex: 1, paddingInline: '1em' }}>
                    <h4 style={{ color: 'white' }}>{item.text}</h4>
                  </div>
                  <div>
                    <IconButton
                      onClick={() => handleClick(item.id)}
                      style={{ backgroundColor: '#fc0' }}>
                      <DeleteOutline style={{ color: 'white' }} />
                    </IconButton>
                  </div>
                </div>
              </div>
            </Paper>
          </div>
        );
      })}
    </div>
  );
}
