import styles from './InternTanzabendDetails.module.css';
import toLocalDateString from '../../../../util/toLocalDateString';
import { FerienDTO } from '../../../../model/FerienDTO';
import { sites } from '../../InternPage';
import IconButton from '@mui/material/IconButton/IconButton';
import EditIcon from '@mui/icons-material/Edit';

type FerienDetailProps = {
  ferien: FerienDTO;
  setSite: (s: sites) => void;
  setFerien: (f: FerienDTO | null) => void;
};

export default function InternFerienDetails({ ferien, setFerien, setSite }: FerienDetailProps) {
  return (
    <div className={styles.tanzabendDetailContainer}>
      <div className={styles.tanzabendDetailTimeContainer}>
        <p style={{ color: '#ffcc00' }}>{ferien.title}</p>
        <p>:</p>
        <p>{toLocalDateString(ferien.startDate)}</p>
        <p> - </p>
        <p>{toLocalDateString(ferien.endDate)}</p>
        <IconButton
          onClick={() => {
            setSite('editHoliday');
            setFerien(ferien);
          }}>
          <EditIcon />
        </IconButton>
      </div>
    </div>
  );
}
