import { useCallback, useEffect, useState } from 'react';
import fetchFromBackend from '../api/fetchFromBackend';
import { AxiosError } from 'axios';
import { ConfigResponse } from '../model/ConfigResponse';

export default function useRemoteConfig() {
  const [config, setConfig] = useState<ConfigResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | AxiosError>(null);

  const load = useCallback(() => {
    setLoading(true);
    fetchFromBackend<ConfigResponse>('remoteConfig/vUrtUCiqLgY8o2jnPm74')
      .then(setConfig)
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => load(), [load]);

  return { config, loading, error };
}
