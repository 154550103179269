import styles from './DatesOverview.module.css';
import TanzabendDetails from './Details/TanzabendDetails';
import useTanzabende from '../../hooks/useTanzabende';
import CustomCircularProgress from '../../util/customLoadingSpinner';
import useFerien from '../../hooks/useFerien';

import FerienDetails from './Details/FerienDetails';
import { TanzabendDTO } from '../../model/TanzabendDTO';
import { FerienDTO } from '../../model/FerienDTO';
import ErrorPage, {
  ERROR_CODE_DATES_OVERVIEW_FERIEN,
  ERROR_CODE_DATES_OVERVIEW_TANZABENDE
} from '../../error/ErrorPage';
import { useEffect, useState } from 'react';
import removeDuplicate from '../../util/removeDuplicate';
import { useConfigContext } from '../../context/ConfigContext';

export default function DatesOverview() {
  const { tanzabende, tanzabendeError, loadTanzabende } = useTanzabende();
  const { ferien, error, loading } = useFerien();

  const [displayedTanzabende, setDisplayTanzabende] = useState<TanzabendDTO[]>([]);
  useEffect(() => {
    if (tanzabende) {
      setDisplayTanzabende([]);
      removeDuplicate<TanzabendDTO>(tanzabende.allCourses, (a, b) => a.id === b.id).forEach(
        (item, index) => {
          setTimeout(() => {
            setDisplayTanzabende((currentItems) => [...currentItems, item]);
          }, 50 * index); // 100ms Verzögerung für jedes Element
        }
      );
    }
  }, [tanzabende]);

  const [displayedFerien, setDisplayedFerien] = useState<FerienDTO[]>([]);
  useEffect(() => {
    if (ferien) {
      setDisplayedFerien([]);
      ferien.allHoliday.forEach((item, index) => {
        setTimeout(() => {
          setDisplayedFerien((currentItems) => [...currentItems, item]);
        }, 50 * index);
      });
    }
  }, [ferien]);

  if (error) return <ErrorPage location={ERROR_CODE_DATES_OVERVIEW_FERIEN} error={error} />;
  if (tanzabendeError)
    return <ErrorPage location={ERROR_CODE_DATES_OVERVIEW_TANZABENDE} error={tanzabendeError} />;

  if (loading || loadTanzabende || ferien === null || tanzabende === null) {
    return <CustomCircularProgress />;
  }

  return (
    <div className={styles.welcomePageContentContainer}>
      <RightSide tanzabende={displayedTanzabende} />
      <LeftSide ferien={displayedFerien} />
    </div>
  );
}

function RightSide({ tanzabende }: { tanzabende: TanzabendDTO[] }) {
  const config = useConfigContext();

  return (
    <div className={styles.rightSide}>
      <div className={styles.rightUpperSide}>
        <div className={styles.termineHeadline}>
          <h1>
            <span className={'grayText'}>Die nächsten </span>
            <span className={styles.priceYellow}>Tanzabende</span>
            <span className={'whiteText'}>.</span>
          </h1>
        </div>
        <p>
          Der Tanzabend gibt allen Paaren die Möglichkeit, das Gelernte in netter und entspannter
          Gesellschaft anderer Paare, in schönem Ambiente und zu abwechslungsreicher Musik
          auszuprobieren und zu festigen.{' '}
        </p>
        <p>Eine Reservierung ist für den Tanzabend nicht notwendig.</p>
        <p>
          Preis für Mitglieder im Premium Club-System:{' '}
          <span className={styles.priceYellow}> {config.TANZABENDE_PRICE_MEMBER_PREMIUM}</span>
        </p>
        <p>
          Preis für alle anderen Mitglieder der Tanzschule:{' '}
          <span className={styles.priceYellow}> {config.TANZABENDE_PRICE_MEMBER}</span>
        </p>
        <p>
          Preis für Nicht-Mitglieder:{' '}
          <span className={styles.priceYellow}> {config.TANZABENDE_PRICE_NOT_MEMBER}</span>
        </p>
        <p style={{ color: '#ffcc00' }}>Änderungen vorbehalten</p>
        <p style={{ fontSize: '17' }}>
          <span style={{ color: '#ffcc00' }}>Unsere telefonischen Bürozeiten:</span>
          <br />
          Mo. + Di. + Do.: 12:00 – 19:00
          <br />
          Mi. + Fr.: 12:00 – 15:00
        </p>
      </div>

      <div className={styles.termineListContainer}>
        <ul className={styles.list}>
          {removeDuplicate<TanzabendDTO>(tanzabende, (a, b) => a.id === b.id).map((tanzabend) => (
            <div className={'fadeIn'}>
              <TanzabendDetails tanzabend={tanzabend} />
            </div>
          ))}
          {tanzabende.length === 0 ? <h2>Aktuell sind keine Termine geplant</h2> : null}
        </ul>
      </div>
    </div>
  );
}

function LeftSide({ ferien }: { ferien: FerienDTO[] }) {
  return (
    <div className={styles.rightSide}>
      <div className={styles.rightUpperSide}>
        <div className={styles.termineHeadline}>
          <h1>
            <span className={'grayText'}>Wir machen </span>
            <span className={styles.priceYellow}>Ferien</span>
            <span className={'whiteText'}>.</span>
          </h1>
        </div>
        <p>An den folgenden Terminen hat unsere Tanzschule geschlossen</p>
        <p>In diesen Zeiten findet kein regulärer Tanzunterricht in den Paarkursen statt. </p>
        <p>
          Die Ferienzeiten unserer Kinder- und Jugenkurse richten sich nach den Schulferien in
          Baden-Württemberg{' '}
        </p>
        <p>Unser Büro ist nicht besetzt, die Beantwortung von Mails kann sich verzögern.</p>
      </div>

      <div className={styles.termineListContainer}>
        <ul className={styles.list}>
          {removeDuplicate<FerienDTO>(ferien, (a, b) => a.id === b.id).map((ferien) => (
            <div className={'fadeIn'}>
              <FerienDetails ferien={ferien} />
            </div>
          ))}
          {ferien.length === 0 ? <h2>Aktuell sind keine Ferien geplant</h2> : null}
        </ul>
      </div>
    </div>
  );
}
