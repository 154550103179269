import axios, { AxiosResponse } from 'axios';
import { HeaderType } from '../model/HeaderType';

export type RequestType = 'post' | 'patch';
export default async function sendAuthenticatedToBackend<T>(
  path: string,
  method: RequestType,
  data: T,
  header: HeaderType
): Promise<AxiosResponse> {
  const backendUrl = `https://api.ettlingen-tanzt.de/${path}`;
  function makeRequest<T>(url: string, data: any) {
    if (method === 'post') {
      return axios.post<T>(url, data, { headers: header });
    } else {
      return axios.patch<T>(url, data, { headers: header });
    }
  }
  return await makeRequest(backendUrl, data);
}
