import { TanzabendDTO } from '../../../../model/TanzabendDTO';
import styles from './InternTanzabendDetails.module.css';
import { toLocalDateWithDayString } from '../../../../util/toLocalDateString';
import IconButton from '@mui/material/IconButton/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { sites } from '../../InternPage';

type TanzabendDetailsProps = {
  tanzabend: TanzabendDTO;
  setTanzabend: (t: TanzabendDTO) => unknown;
  setSite: (s: sites) => unknown;
};

export default function InternTanzabendDetails({
  tanzabend,
  setTanzabend,
  setSite
}: TanzabendDetailsProps) {
  const handleClick = () => {
    setTanzabend(tanzabend);
    setSite('editTanzabend');
  };

  return (
    <div className={styles.tanzabendDetailContainer}>
      <div className={styles.tanzabendDetailTimeContainer}>
        <p>{toLocalDateWithDayString(tanzabend.date)}</p>
        <p> - </p>
        <p className={styles.detailTime}>{tanzabend.time}</p>
      </div>
      <p>{tanzabend.title}</p>
      <IconButton onClick={handleClick}>
        <EditIcon />
      </IconButton>
    </div>
  );
}
