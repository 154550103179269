import { useCallback, useEffect, useState } from 'react';
import { AllCoursesDTO } from '../model/CourseDTO';
import fetchFromBackend from '../api/fetchFromBackend';

export default function useRefreshs() {
  const [refreshs, setRefreshs] = useState<AllCoursesDTO | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const path = 'refreshs';

  const load = useCallback(() => {
    setLoading(true);
    fetchFromBackend<AllCoursesDTO>(path)
      .then(setRefreshs)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [path]);
  useEffect(() => load(), [load]);

  return { refreshs, loading, error, reload: load };
}
