import { useCallback, useEffect, useState } from 'react';
import fetchFromBackend from '../api/fetchFromBackend';
import { AxiosError } from 'axios';
import { AllClubsDTO } from '../model/ClubDTO';

export default function useClubs() {
  const [clubs, setClubs] = useState<AllClubsDTO | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | AxiosError>(null);

  const load = useCallback(() => {
    setLoading(true);
    fetchFromBackend<AllClubsDTO>('clubs')
      .then(setClubs)
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => load(), [load]);

  return { clubs, loading, error, reloadClubs: load };
}
