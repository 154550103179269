import axios, { AxiosResponse } from 'axios';
import { HeaderType } from '../model/HeaderType';

export default async function sendDelete(
  path: string,
  id: string,
  header: HeaderType
): Promise<AxiosResponse> {
  const backendUrl = `https://api.ettlingen-tanzt.de/${path}/${id}`;

  return await axios.delete(backendUrl, { headers: header });
}
