import styles from './InternClubPaperStyles.module.css';
import getDifficultyColor from '../../../util/getDifficultyColor';
import { ClubDTO } from '../../../model/ClubDTO';
import IconButton from '@mui/material/IconButton/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { sites } from '../InternPage';

type ClubPaperProps = {
  club: ClubDTO;
  setSite: (site: sites) => unknown;
  setClub: (cub: ClubDTO) => unknown;
};

export default function InternClubPaper({ club, setSite, setClub }: ClubPaperProps) {
  const handleClick = () => {
    setSite('editClub');
    setClub(club);
  };

  return (
    <div
      className={styles.tanzabendDetailContainer}
      style={{ border: `6px solid ${getDifficultyColor(club.level)}` }}>
      <div className={styles.tanzabendDetailTimeContainer}>
        <p style={{ color: '#fff' }}>{club.day}</p>
        <p style={{ color: '#fff' }}>-</p>
        <p style={{ color: '#fff' }}>{club.time}</p>
        <p />
        <p style={{ color: '#ffcc00' }}>Kursleiter: {club.teacher}</p>
      </div>
      <IconButton onClick={handleClick}>
        <EditIcon />
      </IconButton>
    </div>
  );
}
