import React from 'react';
import styles from './TrafficLight.module.css';
import { Tooltip } from '@mui/material';

const TrafficLight = ({ status }: { status: string }) => {
  return (
    <Tooltip
      title={<h1 style={{ textAlign: 'center', marginBottom: '0.2em' }}>{getTooltip(status)}</h1>}
      placement="right"
      arrow
      enterTouchDelay={50}>
      <div
        className={`${styles.trafficLight} ${status === 'ausgebucht' ? styles.trafficLightAusgebucht : ''}`}>
        <div className={`${styles.light} ${status === 'red' ? styles.red : ''}`}></div>
        <div className={`${styles.light} ${status === 'yellow' ? styles.yellow : ''}`}></div>
        <div
          className={`${styles.light} ${status === 'green' || status === 'ausgebucht' ? styles.green : ''}`}></div>
      </div>
    </Tooltip>
  );
};

function getTooltip(status: string): string {
  if (status === 'ausgebucht') {
    return 'Das Event ist ausgebucht!';
  }
  if (status === 'red') {
    return 'Das Event findet nicht statt!';
  } else if (status === 'yellow') {
    return 'Mindestteilnehmerzahl nicht erreicht';
  } else {
    return 'Das Event findet statt!';
  }
}

export default TrafficLight;
