export default function toLocalDateString(dateStr: string) {
  if (!dateStr) return '';
  if (dateStr.includes('.')) return dateStr;

  const [year, month, day] = dateStr.split('-');
  if (year.length === 2) return `${day}.${month}.${year}`;
  return `${day}.${month}.${year.substring(2, 4)}`;
}
export function toLocalDateWithDayString(dateStr: string): string {
  if (!dateStr) return '';
  if (dateStr.includes('.')) return dateStr;

  const [year, month, day] = dateStr.split('-').map((part) => parseInt(part));
  const date = new Date(year, month - 1, day); // Monate sind in JavaScript 0-basiert

  const dayNames = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
  const dayName = dayNames[date.getDay()];

  const formattedDay = day < 10 ? `0${day}` : `${day}`;
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedYear = year.toString();

  return `${dayName}. ${formattedDay}.${formattedMonth}.${formattedYear}`;
}
