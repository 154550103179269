import CoursePage from './CoursePage';
import useSpecialCourses from '../../hooks/useSpecialCourses';

export default function SpecialCoursesPage() {
  const { specials, loading, error } = useSpecialCourses();
  return (
    <CoursePage
      courses={specials}
      loading={loading}
      error={error}
      pageTitle={'TCE-Sonderkurse'}
      category={'special'}
    />
  );
}
