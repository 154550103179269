import { CategoryType } from '../features/intern/coursePage/edit/EditCoursePage';
import { ConfigResponse } from '../model/ConfigResponse';

export function getDeadlineForCategory(category: CategoryType, config: ConfigResponse) {
  switch (category) {
    case 'refresh':
      return Number.parseInt(config.DAYS_REGISTRATION_DEADLINE_REFRESH, 10);
    case 'special':
      return Number.parseInt(config.DAYS_REGISTRATION_DEADLINE_TCECOURSES, 10);
    case 'tceevent':
      return Number.parseInt(config.DAYS_REGISTRATION_DEADLINE_EVENT, 10);
    case 'workshop':
      return Number.parseInt(config.DAYS_REGISTRATION_DEADLINE_WORKSHOP, 10);
    case 'youthparty':
      return Number.parseInt(config.DAYS_REGISTRATION_DEADLINE_YOUTHPARTY, 10);
  }
  return Number.parseInt(config.DAYS_REGISTRATION_DEADLINE_FALLBACK, 10);
}
