import CoursePage from './CoursePage';
import useYouthpartys from '../../hooks/useYouthpartys';

export default function YouthpartyPage() {
  const { partys, loading, error } = useYouthpartys();
  return (
    <CoursePage
      courses={partys}
      loading={loading}
      error={error}
      pageTitle={'Jugend'}
      category={'youthparty'}
    />
  );
}
