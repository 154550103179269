import { useCallback, useEffect, useState } from 'react';
import { AllCoursesDTO } from '../model/CourseDTO';
import fetchFromBackend from '../api/fetchFromBackend';

export default function useYouthpartys() {
  const [partys, setPartys] = useState<AllCoursesDTO | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const path = 'youthpartys';

  const load = useCallback(() => {
    setLoading(true);

    fetchFromBackend<AllCoursesDTO>(path)
      .then(setPartys)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [path]);
  useEffect(() => load(), [load]);

  return { partys, loading, error, reload: load };
}
