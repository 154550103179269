import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Overview from './features/Overview/Overview';
import './AppRouter.css';
import RegistrationSite from './features/registration/RegistrationSite';
import { AuthProvider } from './context/AuthContext';
import { ConfigProvider } from './context/ConfigContext';
import Newsfeed from './features/News/Newsfeed';
import NewsfeedOverview from './features/News/Intern/NewsfeedOverview';
import NewNewsForm from './features/News/Intern/Form/NewNewsForm';
import InternPage from './features/intern/InternPage';

export default function AppRouter() {
  return (
    <ConfigProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path={'*'}
            Component={() => (
              <BackgroundAndFooter>
                <Overview />
              </BackgroundAndFooter>
            )}
          />
          <Route
            path={'/courses'}
            Component={() => (
              <BackgroundAndFooter>
                <Overview initalSite={'specialCourses'} />
              </BackgroundAndFooter>
            )}
          />
          <Route
            path={'/jugend'}
            Component={() => (
              <BackgroundAndFooter>
                <Overview initalSite={'youthDates'} />
              </BackgroundAndFooter>
            )}
          />
          <Route
            path={'/refresh'}
            Component={() => (
              <BackgroundAndFooter>
                <Overview initalSite={'refresh'} />
              </BackgroundAndFooter>
            )}
          />
          <Route
            path={'/workshops'}
            Component={() => (
              <BackgroundAndFooter>
                <Overview initalSite={'workshops'} />
              </BackgroundAndFooter>
            )}
          />
          <Route
            path={'/events'}
            Component={() => (
              <BackgroundAndFooter>
                <Overview initalSite={'tceevents'} />
              </BackgroundAndFooter>
            )}
          />
          <Route
            path={'/clubfinder'}
            Component={() => (
              <BackgroundAndFooter>
                <Overview initalSite={'clubfinder'} />
              </BackgroundAndFooter>
            )}
          />
          <Route
            path={'/dates'}
            Component={() => (
              <BackgroundAndFooter>
                <Overview initalSite={'dates'} />
              </BackgroundAndFooter>
            )}
          />
          <Route
            path={'/membershipInfos'}
            Component={() => (
              <BackgroundAndFooter>
                <Overview initalSite={'membershipInfos'} />
              </BackgroundAndFooter>
            )}
          />
          <Route
            path={'/reserve/:id'}
            Component={() => (
              <BackgroundAndFooter>
                <RegistrationSite />
              </BackgroundAndFooter>
            )}
          />
          <Route
            path={'/success'}
            Component={() => (
              <BackgroundAndFooter>
                <Overview initalSite={'success'} />
              </BackgroundAndFooter>
            )}
          />
          <Route
            path={'/intern'}
            Component={() => (
              <BackgroundAndFooter>
                <AuthProvider>
                  <InternPage />
                </AuthProvider>
              </BackgroundAndFooter>
            )}
          />
          <Route path={'/NewsFeed'} Component={Newsfeed} />
          <Route
            path={'/newNews'}
            Component={() => (
              <AuthProvider>
                <BackgroundAndFooter>
                  <NewNewsForm />
                </BackgroundAndFooter>
              </AuthProvider>
            )}
          />
          <Route
            path={'/NewsFeedOverview'}
            Component={() => (
              <AuthProvider>
                <BackgroundAndFooter>
                  <NewsfeedOverview />
                </BackgroundAndFooter>
              </AuthProvider>
            )}
          />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

function BackgroundAndFooter({ children }: any) {
  return (
    <div className={'backgroundImage'}>
      {children}
      <footer>
        <div className={'footerContainer'}>
          <div className={'staticKram'}>
            <span>TanzCentrum Ettlingen</span>
            <span className={'white'}>Betriebsstätte: Robert-Bosch-Straße 5, 76275 Ettlingen</span>
          </div>
          <div className={'linkContainer'}>
            <a href={'https://www.tanzcentrum-ettlingen.de/impressum'}>Impressum</a>
            <a href={'https://www.tanzcentrum-ettlingen.de/datenschutz'}>Datenschutzerklärung</a>
            <a href={'https://www.tanzcentrum-ettlingen.de/kontakt'}>Kontakt</a>
          </div>
        </div>
      </footer>
    </div>
  );
}
