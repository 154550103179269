import { createTheme } from '@mui/material/styles';

const formTheme = createTheme({
  palette: {
    primary: {
      main: '#ffcc00'
    },
    background: {
      default: '#aaaaaaaa'
    },
    text: {
      primary: '#ffffff',
      secondary: '#ffffff'
    }
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            color: 'white'
          },
          label: {
            color: 'white'
          },
          '& label.Mui-focused': {
            color: 'white'
          },
          '& .MuiInput-underline': {
            borderBottomColor: '#ffffff'
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#ffcc00'
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white'
            },
            '&:hover fieldset': {
              borderColor: 'white'
            },
            '&.Mui-focused fieldset': {
              borderColor: '#ffcc00'
            }
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'white'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          borderColor: '#ffcc00',
          '&:hover': {
            backgroundColor: '#ffcc00',
            color: 'black'
          }
        }
      }
    }
  }
});

export default formTheme;
