import React, { useEffect, useState } from 'react';
import useClubs from '../../hooks/useClubs';
import { ClubDTO } from '../../model/ClubDTO';
import removeDuplicate from '../../util/removeDuplicate';
import InfoSection from '../coursePage/InfoSection';
import ClubPaper from './ClubPaper';
import styles from '../coursePage/CoursePage.module.css';
import CustomCircularProgress from '../../util/customLoadingSpinner';
import ErrorPage, { ERROR_CODE_CLUB_FINDER_GET } from '../../error/ErrorPage';

export default function Clubfinder() {
  const { clubs, error, loading } = useClubs();
  const [displayedClubs, setDisplayedClubs] = useState<ClubDTO[]>([]);
  useEffect(() => {
    if (clubs) {
      setDisplayedClubs([]);
      removeDuplicate<ClubDTO>(clubs.allClubs, (a, b) => a.id === b.id).forEach((item, index) => {
        setTimeout(() => {
          setDisplayedClubs((currentItems) => [...currentItems, item]);
        }, 50 * index);
      });
    }
  }, [clubs]);
  if (loading) return <CustomCircularProgress />;
  if (error) return <ErrorPage location={ERROR_CODE_CLUB_FINDER_GET} error={error} />;
  return (
    <div style={{ paddingBottom: '10vh' }}>
      <div className={styles.searchContainer}>
        <h1>
          <span className={'grayText'}>Club-Finder</span>
          <span className={'whiteText'}>.</span>
        </h1>
      </div>
      <InfoSection pageTitle={'Club-Finder'} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        {removeDuplicate<ClubDTO>(displayedClubs, (a, b) => a.id === b.id).map(
          (club, index, array) => {
            let style = {};
            if (index > 0 && club.level !== array[index - 1].level) {
              style = { marginTop: '20px' };
            }
            return (
              <div className={'fadeIn'} style={style}>
                <ClubPaper club={club} />
              </div>
            );
          }
        )}
      </div>
    </div>
  );
}
