import { CourseDTO } from '../model/CourseDTO';
import { CategoryType } from '../features/intern/coursePage/edit/EditCoursePage';
import { ConfigResponse } from '../model/ConfigResponse';
import { getDeadlineForCategory } from './getDeadlineForCategory';

export default function getTrafficLightStatus(
  course: CourseDTO,
  category: CategoryType,
  config: ConfigResponse
) {
  if (course.noOfParticipants >= course.minNoOfParticipants && !course.reservable) {
    return 'ausgebucht';
  }
  if (course.noOfParticipants >= course.minNoOfParticipants) {
    return 'green';
  } else {
    const startDate = new Date(course.startDate);
    const deadlineDays = getDeadlineForCategory(category, config);
    const deadlineDate = new Date(startDate);
    deadlineDate.setDate(deadlineDate.getDate() - deadlineDays);
    if (new Date() > deadlineDate) {
      return 'red';
    } else {
      return 'yellow';
    }
  }
}
