import { useState } from 'react';
import sendToBackend, { RequestType } from '../api/sendToBackend';
import { AxiosError, AxiosResponse } from 'axios';

const usePostData = (path: string, method: RequestType) => {
  const [response, setResponse] = useState<AxiosResponse | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const postData = async (data: any, onSuccess?: () => void) => {
    setIsLoading(true);
    sendToBackend<typeof data>(path, method, data)
      .then((r) => {
        setResponse(r);
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(setError)
      .finally(() => setIsLoading(false));
  };

  return { response, error, isLoading, postData };
};

export default usePostData;
