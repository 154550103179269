import CoursePage from './CoursePage';
import useRefreshs from '../../hooks/useRefreshs';

export default function RefreshsPage() {
  const { refreshs, loading, error } = useRefreshs();
  return (
    <CoursePage
      courses={refreshs}
      loading={loading}
      error={error}
      pageTitle={'Refreshtanzen'}
      category={'refresh'}
    />
  );
}
