import styles from './LoginPage.module.css';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Button, InputAdornment, TextField } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import LoginTheme from './LoginTheme';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function LoginPage() {
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const { isLoggedIn, login } = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/intern');
    }
  }, [isLoggedIn, navigate]);
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleButtonClick = () => {
    login(username, password, () => setWrongPassword(true));
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleButtonClick();
    }
  };
  return (
    <div className={styles.welcomePageContainer}>
      <div className={styles.box}>
        <h1>Herzlich Willkommen</h1>
        <p>Bitte melde dich an!</p>
        <div className={styles.loginContainer}>
          <ThemeProvider theme={LoginTheme}>
            <TextField
              fullWidth
              onSubmit={handleButtonClick}
              margin="normal"
              type={'text'}
              label="Nutzername"
              autoFocus
              value={username}
              onChange={handleUsernameChange}
              helperText={wrongPassword ? 'Logindaten nicht korrekt' : ''}
            />
            <TextField
              fullWidth
              onSubmit={handleButtonClick}
              margin="normal"
              type={showPassword ? 'text' : 'password'}
              label="Passwort"
              autoFocus
              value={password}
              onChange={handlePasswordChange}
              error={wrongPassword}
              onKeyDown={handleKeyPress}
              helperText={wrongPassword ? 'Logindaten nicht korrekt' : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              className={styles.loginButton}
              onClick={handleButtonClick}
              disabled={!username || !password}>
              Login
            </Button>
          </ThemeProvider>
        </div>
        <p>
          Zurück zur <a href={'/'}>Kundenseite</a>
        </p>
      </div>
    </div>
  );
}
