import styles from './MembershipInfoPage.module.css';
import PremiumContainer from './PremiumContainer';
import BasicContainer from './BasicContainer';
import AFContainer from './AFContainer';

export default function MembershipInfoPage() {
  return (
    <div className={styles.container}>
      <AFContainer />
      <BasicContainer />
      <PremiumContainer />
    </div>
  );
}
