import Box from '@mui/material/Box';
import React from 'react';
import styles from './BasicContainer.module.css';
import EmailButton from './EmailButton';
import { Divider } from '@mui/material';

export default function BasicContainer() {
  return (
    <Box className={styles.container}>
      <div className={styles.content}>
        <h1>Basic-Mitglied</h1>

        <ul className={styles.ul}>
          <li>
            Laufzeit: <span className={styles.important}>6 Monate</span>
          </li>
          <li>
            Preis: <span className={styles.important}>€ 14,90 </span>
          </li>
          <li>wöchentliche Abbuchung</li>
          <li> Preis pro Person</li>
          <li>
            {' '}
            keine Abbuchung in den{' '}
            <a
              href={'https://tcevent.tanzcentrum-ettlingen.de/dates'}
              target={'_blank'}
              rel="noreferrer">
              Tanzschulferien
            </a>
          </li>
          <li> monatlich kündbar nach der Grundlaufzeit</li>
          <li>
            Preis bei monatlicher Laufzeit: <span className={styles.important}>€23,90</span>
          </li>
          <Divider style={{ backgroundColor: '#fff', width: '100%' }} />
          <li>1 Tanztermin / Woche / 60 Minuten</li>
          <li>Wechsel in andere Clubs nach Absprache möglich</li>
        </ul>
      </div>
      <EmailButton membershipType={'Basic'} />
    </Box>
  );
}
