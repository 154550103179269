import React, { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import '../../coursePage/edit/EditCoursePage.module.css';
import { ThemeProvider } from '@mui/material/styles';
import formTheme from '../../../../features/registration/FormTheme';
import CustomCircularProgress from '../../../../util/customLoadingSpinner';
import { RequestType } from '../../../../api/sendToBackend';
import { TanzabendDTO } from '../../../../model/TanzabendDTO';
import useSendDelete from '../../../../hooks/useSendDelete';
import ErrorPage, {
  ERROR_CODE_EDIT_TANZABEND_DELETE,
  ERROR_CODE_EDIT_TANZABEND_SEND
} from '../../../../error/ErrorPage';
import removeEmojis from '../../../../util/removeEmojis';
import usePostInternData from '../../../../hooks/usePostInternData';

export default function EditTanzabendPage({
  tanzabend,
  close
}: {
  tanzabend: TanzabendDTO | null;
  close: () => void;
}) {
  const [title, setTitle] = useState(tanzabend?.title ?? '');
  const [date, setDate] = useState(tanzabend?.date ?? '');
  const [time, setTime] = useState(tanzabend?.time ?? '');

  const path: string = !tanzabend ? 'tanzabend' : `tanzabend/${tanzabend.id}`;
  const method: RequestType = !tanzabend ? 'post' : 'patch';
  const { isLoading, error, postData } = usePostInternData(path, method);
  const { deleteLoading, deleteError, deleteData } = useSendDelete(
    'tanzabend',
    tanzabend?.id ?? '0'
  );

  const isFormValid = () => {
    return !(!title || !time || !date);
  };

  const handleDelete = () => {
    const handleSuccess = () => {
      close();
    };
    deleteData(handleSuccess);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    postData(
      {
        title: removeEmojis(title),
        date: removeEmojis(date),
        time: removeEmojis(time)
      },
      close
    );
  };

  if (error) return <ErrorPage location={ERROR_CODE_EDIT_TANZABEND_SEND} error={error} />;
  if (deleteError)
    return <ErrorPage location={ERROR_CODE_EDIT_TANZABEND_DELETE} error={deleteError} />;

  if (isLoading || deleteLoading) {
    return <CustomCircularProgress />;
  }
  return (
    <ThemeProvider theme={formTheme}>
      <div style={{ paddingBottom: '8vh' }}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            mt: 1,
            backgroundColor: 'background.default',
            color: 'text.primary',
            borderRadius: '1.5em',
            marginInline: '5em',
            marginTop: '5em',
            marginBottom: '-5em',
            padding: '2em'
          }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              variant={'standard'}
              label="Titel"
              autoFocus
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              variant={'standard'}
              label="Startdatum"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              variant={'standard'}
              label="Startzeit"
              type="time"
              InputLabelProps={{ shrink: true }}
              value={time}
              onChange={(e) => setTime(e.target.value)}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
            <Button
              type={'button'}
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              onClick={close}>
              Abbrechen
            </Button>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!isFormValid()}
              sx={{ mt: 3, mb: 2 }}>
              Absenden
            </Button>
          </div>

          {tanzabend && (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="warning"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleDelete}>
                Löschen
              </Button>
            </div>
          )}
        </Box>
      </div>
    </ThemeProvider>
  );
}
