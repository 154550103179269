import React, { createContext, useContext } from 'react';
import useRemoteConfig from '../hooks/useRemoteConfig';
import { ConfigResponse } from '../model/ConfigResponse';
import CustomCircularProgress from '../util/customLoadingSpinner';
import ErrorPage, { ERROR_CODE_LOAD_REMOTE_CONFIG } from '../error/ErrorPage';

const ConfigContext = createContext<ConfigResponse>({});

function ConfigProvider({ children }: any) {
  const { config, loading, error } = useRemoteConfig();

  if (loading || config === null) {
    return <CustomCircularProgress />;
  }

  if (error) return <ErrorPage location={ERROR_CODE_LOAD_REMOTE_CONFIG} error={error} />;

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
}

function useConfigContext() {
  return useContext(ConfigContext);
}

export { ConfigProvider, useConfigContext };
