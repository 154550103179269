import { useCallback, useEffect, useState } from 'react';
import { AllFerienDTO } from '../model/FerienDTO';
import { AxiosError } from 'axios';
import fetchAuthenticatedFromBackend from '../api/fetchAuthenticatedFromBackend';
import { useAuth } from '../context/AuthContext';

export default function useInternFerien() {
  const [ferien, setFerien] = useState<AllFerienDTO | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | AxiosError>(null);
  const { getHeader, logout } = useAuth();
  const path = 'intern/ferien';

  const load = useCallback(() => {
    setLoading(true);
    const header = getHeader();
    fetchAuthenticatedFromBackend<AllFerienDTO>(path, header)
      .then(setFerien)
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          logout();
        } else {
          setError(err);
        }
      })
      .finally(() => setLoading(false));
  }, [getHeader, logout]);

  useEffect(() => load(), [load]);

  return { ferien, loading, error, reloadFerien: load };
}
